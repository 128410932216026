import { Box } from '@material-ui/core';
import classnames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import Loading from 'src/components/Loading';
import styles from 'src/features/PositionAndHistory/styles/Order.module.scss';
import { formatOrderEnum } from 'src/helpers/numberFormatter';
import { formatTime } from 'src/helpers/stringFormatter';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import DatePicker from 'src/components/cores/DatePicker/DatePicker';
import { DateRange } from '@material-ui/pickers';
import { listTimeSelect, SelectTimeValue } from './constant';
import moment from 'moment';
import { Pagination } from '@mui/material';
import { RECORDS_PER_PAGE, TradeSide, TypeTrade } from 'src/constants/common.constants';
import { getFilledTradesBySymbol } from 'src/services/trade';
import { IFill } from 'src/interfaces/fill';
import { handleDirect } from './PositionRecord';
import { getCookieStorage } from 'src/helpers/storage';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { formatOrderText, getContractType, getSymbolName } from './helper';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'src/hooks/useScreen';
import { THEME_MODE } from 'src/interfaces/theme';
// import { noData, noDataDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import TextNumber from 'src/components/cores/TextNumber';
import { BsInboxesFill } from "react-icons/bs";

const cx = classnames.bind(styles);

const TradeHistory = (props: any) => {
  const { hideOtherPairs } = props;
  const dispatch = useAppDispatch();
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = useAppSelector((state) => state.instrument.currentInstrument);
  const [tab, setTab] = useState<number | null>(0);
  const [tabSelectValue, setTabSelectValue] = useState<string | null>(SelectTimeValue.ONE_DAY);

  const { fills, loading } = useAppSelector((state) => state.tradeHistory);
  const account = useAppSelector((state) => state.account.account);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);

  const [rows, setRows] = useState<IFill[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPage = useAppSelector((state) => state.tradeHistory.totalPage);
  const changeCheckboxRef = useRef(hideOtherPairs);
  const changeInstrumentRef = useRef(currentInstrument.symbol);

  const selectedDatePickerRef = useRef<DateRange<Date>>([null, null]);
  const [selectedDatePicker, setSelectedDatePicker] = useState<DateRange<Date>>([null, null]);
  const [isChangeSelectDatePicker, setIsChangeSelectDatePicker] = useState<boolean>(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const token = getCookieStorage('access_token');
  const isExpired = getCookieStorage('isExpired');
  const isAuthenticated = isExpired === 'false' && token && token !== 'undefined' 
  // && account && account[0]?.id;
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const { t } = useTranslation('common');
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const handleFormatTime = () => {
    let formatStartTime;
    let formatEndTime;
    if (tabSelectValue === SelectTimeValue.ONE_DAY) {
      formatStartTime = moment().startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_WEEK) {
      formatStartTime = moment().subtract(1, 'weeks').startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_MONTH) {
      formatStartTime = moment().subtract(1, 'months').startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.THREE_MONTHS) {
      formatStartTime = moment().subtract(3, 'months').startOf('day').utc().toDate().getTime();
      formatEndTime = moment().endOf('day').utc().toDate().getTime();
    }
    return {
      formatStartTime,
      formatEndTime,
    };
  };

  const fetchData = async (page: number) => {
    let isChooseDatePicker = isChangeSelectDatePicker;
    if (!selectedDatePicker[0] || !selectedDatePicker[1]) {
      isChooseDatePicker = false;
    }
    const { formatStartTime, formatEndTime } = handleFormatTime();
    const formatStartDatePicker = moment(selectedDatePicker[0]).startOf('day').utc().toDate().getTime();
    const formatEndDatePicker = moment(selectedDatePicker[1]).endOf('day').utc().toDate().getTime();

    const params = { page: page, size: RECORDS_PER_PAGE };

    const payload: any = {
      startTime: 0,
      endTime: 0,
      side: 'ALL',
      contractType,
    };

    if (hideOtherPairs) {
      payload.symbol = currentInstrument.symbol;
    }

    if (formatStartTime && !isChooseDatePicker) {
      payload.startTime = formatStartTime;
    }
    if (formatEndTime && !isChooseDatePicker) {
      payload.endTime = formatEndTime;
    }
    if (formatStartDatePicker && isChooseDatePicker) {
      payload.startTime = formatStartDatePicker;
    }
    if (formatEndDatePicker && isChooseDatePicker) {
      payload.endTime = formatEndDatePicker;
    }

    await dispatch(getFilledTradesBySymbol({ params, payload }));
  };

  useEffect(() => {
    if (fills) {
      setRows(fills);
    }
  }, [fills, account[0]?.id]);

  useEffect(() => {
    if (isAuthenticated) {
      if (changeCheckboxRef.current === hideOtherPairs && changeInstrumentRef.current === currentInstrument.symbol) {
        fetchData(currentPage);
      } else {
        setCurrentPage(1);
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [tabSelectValue, hideOtherPairs, currentInstrument]);

  useEffect(() => {
    if (
      !isOpenDatePicker &&
      isChangeSelectDatePicker &&
      selectedDatePicker[0] &&
      selectedDatePicker[1] &&
      isAuthenticated
    ) {
      setTab(null);
      setTabSelectValue(null);
      setCurrentPage(1);
      fetchData(1);
    }
  }, [isOpenDatePicker, isChangeSelectDatePicker, selectedDatePicker]);

  const renderPrice = (fill: IFill, currentPrice: string) => {
    const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === fill?.symbol);
    const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return <TooltipNumber characters={11} decimal={maxFiguresForPrice} defaultValue={currentPrice} />;
  };

  const renderQuantity = (fill: IFill) => {
    const currentIns = instruments?.find((instrument) => instrument.symbol === fill.symbol);
    const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === fill?.symbol);
    const maxFiguresForSize = Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={9}
        decimal={isCoinM ? 0 : maxFiguresForSize}
        symbol={isCoinM ? 'Cont' : currentIns?.rootSymbol}
        defaultValue={fill?.quantity}
      />
    );
  };

  const renderFee = (fill: IFill) => {
    const currentInstrument = instruments?.find((instrument) => instrument?.symbol === fill?.symbol);

    // return (
    //   <TooltipNumber
    //     defaultValue={fill.fee}
    //     characters={13}
    //     decimal={8}
    //     symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
    //   />
    // );

    return (
      <TextNumber
        defaultValue={fill.fee ?? '0'}
        symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
        isCoinM={false}
      />
    );
  };

  const renderRealizedProfit = (fill: IFill) => {
    const currentInstrument = instruments?.find((instrument) => instrument?.symbol === fill?.symbol);

    if (fill?.tradeSide === TradeSide.BUY) {
      if (Number(fill?.realizedPnlOrderBuy)) {
        // return (
        //   <TooltipNumber
        //     defaultValue={fill.realizedPnlOrderBuy}
        //     characters={20}
        //     decimal={8}
        //     symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
        //   />
        // );

        return (
          <TextNumber
            defaultValue={fill.realizedPnlOrderBuy ?? '0'}
            symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
            isCoinM={false}
          />
        );
      } else {
        return '--';
      }
    } else {
      if (Number(fill?.realizedPnlOrderSell)) {
        // return (
        //   <TooltipNumber
        //     defaultValue={fill.realizedPnlOrderSell}
        //     characters={20}
        //     decimal={8}
        //     symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
        //   />
        // );

        return (
          <TextNumber
            defaultValue={fill.realizedPnlOrderSell ?? '0'}
            symbol={isCoinM ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
            isCoinM={false}
          />
        );
      } else {
        return '--';
      }
    }
  };

  const handleChangeDatePicker = (value: DateRange<Date>) => {
    setSelectedDatePicker(value);
  };

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDatePickerRef.current[0]).format('YYYY-MM-DD') !==
        moment(selectedDatePicker[0]).format('YYYY-MM-DD') ||
      moment(selectedDatePickerRef.current[1]).format('YYYY-MM-DD') !==
        moment(selectedDatePicker[1]).format('YYYY-MM-DD')
    ) {
      setIsChangeSelectDatePicker(true);
    } else {
      setIsChangeSelectDatePicker(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDatePickerRef.current = selectedDatePicker;
    setIsOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setIsOpenDatePicker(false);
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDatePicker]);

  // const handleSearchHistory = () => {
  //   if (isChangeSelectDatePicker) {
  //     setTab(null);
  //     setTabSelectValue(null);
  //   }
  //   setCurrentPage(1);
  //   fetchData(1);
  // };

  const handleReset = () => {
    setTab(0);
    setTabSelectValue(SelectTimeValue.ONE_DAY);
    setIsChangeSelectDatePicker(false);
    setCurrentPage(1);
    fetchData(1);
  };

  return (
    <div className={'height-100'}>
      {isAuthenticated && (
        <Box>
          <div className={cx('container')}>
            <div className={cx('tab-wrapper')}>
              {listTimeSelect.map((item: { label: string; value: string }, index: number) => {
                return (
                  <div
                    key={item?.label}
                    onClick={() => {
                      setTab(index);
                      setTabSelectValue(item.value);
                      setIsChangeSelectDatePicker(false);
                      setCurrentPage(1);
                    }}
                    className={tab === index ? cx('tab', 'active') : cx('tab')}
                  >
                    {t(item?.label)}
                  </div>
                );
              })}
              <div className={cx('text-time')}>{t('order.time')}</div>
              <DatePicker
                handleChangeDate={handleChangeDatePicker}
                handleOpenDate={handleOpenDatePicker}
                handleCloseDate={handleCloseDatePicker}
                customInput={cx('input-time')}
                isChangeSelectDatePicker={isChangeSelectDatePicker}
              />
              <div>
                {/* <button className={cx('search-btn')} onClick={handleSearchHistory}>
                  Search
                </button> */}
                <button className={cx('reset-btn')} onClick={handleReset}>
                  {t('order.reset')}
                </button>
              </div>
            </div>
          </div>
        </Box>
      )}

      <div className={cx('tbl_data')}>
        {!isAuthenticated && (
          <div className={cx('no-orders')}>
            <div className={cx('warning-table')}>
              <strong onClick={() => handleDirect('login')}>{t('order.login')}</strong> {t('order.or')}{' '}
              <strong onClick={() => handleDirect('signup')}>{t('order.signup')}</strong> {t('order.to_start_trading')}
            </div>
          </div>
        )}

        {isAuthenticated && (
          <div className={'height-100'} style={{ position: 'relative' }}>
            <div className={cx('table-content')} style={{ minHeight: '395px' }}>
              <div
                className={cx('no-data')}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '25%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {!loading && !rows.length && (
                  <>
                    <BsInboxesFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      style={{ zoom: 5 }}
                    />
                    <div className={cx('no-data-desc')}>{t('order.no_trades')}</div>
                  </>
                )}

                {loading && <Loading />}
              </div>

              <table className={cx('dex_table', 'text-table-xs')}>
                <thead>
                  <tr>
                    <th style={{ minWidth: '235px', width: '16%' }}>{t('order.time')}</th>
                    <th style={{ minWidth: '160px', width: '11%' }}>{t('order.symbol')}</th>
                    <th style={{ minWidth: '120px', width: '9%' }}>{t('order.side')}</th>
                    <th style={{ minWidth: '150px', width: '10%' }}>{t('order.price')}</th>
                    <th style={{ minWidth: '135px', width: '9%' }}>{t('order.quantity')}</th>
                    <th style={{ minWidth: '190px', width: '12%' }}>{t('order.fee')}</th>
                    <th style={{ minWidth: '190px', width: '12%' }}>{t('order.role')}</th>
                    <th style={{ minWidth: '140px', width: '10%' }}>{t('order.realized_profit')}</th>
                  </tr>
                </thead>
                <tbody
                  className={cx('dex_table__scrollbar', 'overflow-tbody')}
                  style={{ maxHeight: 210, verticalAlign: 'top' }}
                >
                  {loading && <div style={{ height: 230 }}></div>}
                  {!loading && !rows.length && <div style={{ height: 230 }}></div>}

                  {!loading && !!rows.length && (
                    <>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className={cx('text-value')}>{formatTime(row.createdAt)}</td>
                          <td>
                            <div className={cx('text-value')}>{getSymbolName(row.symbol ?? '')}</div>
                            <div className={cx('text-perpetual')}>{t('order.perpetual')}</div>
                          </td>
                          <td className={cx(row?.tradeSide === TradeSide.BUY ? 'color-buy' : 'color-sell')}>
                            {t(formatOrderText(row.tradeSide))}
                          </td>
                          <td className={cx('text-value')}>{renderPrice(row, row.price ?? '0')}</td>
                          <td className={cx('text-value')}>{renderQuantity(row)}</td>
                          <td className={cx('text-value')}>{renderFee(row)}</td>
                          <td className={cx('text-value')}>{t(formatOrderText(row.liquidity))}</td>
                          <td className={cx('text-value')}>{renderRealizedProfit(row)}</td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              {!loading && totalPage > 1 && (
                <div className={cx('pagination')}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, page) => {
                      setCurrentPage(page);
                      fetchData(page);
                    }}
                    count={totalPage}
                    variant="outlined"
                    shape="rounded"
                    className={cx('pagination-custom')}
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'var(--color-value-1) !important',
                      },
                      '& .Mui-selected': {
                        backgroundColor: 'var(--color-background-pagination) !important',
                        border: '1px solid var(--color-outline-pagination) !important',
                        color: 'var(--color-text-pagination) !important',
                        borderRadius: '5px !important',
                      },
                      '& .MuiPagination-ul': {
                        flexWrap: 'nowrap',
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TradeHistory;
