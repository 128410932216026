// eslint-disable-next-line
import BigNumber from "bignumber.js";
import { IMarketTrade } from "src/features/OrderbookTrade/redux/MarketTrade.slice";
import {
  clearOrderbook,
  getOrderbook,
  IOrderbook,
  updateOrderbook,
} from "src/features/OrderbookTrade/redux/Orderbook.slice";
import { RootStore } from "src/store/store";

export const onReceiveUpdates = (store: RootStore, data: IOrderbook) => {
  const state = store.getState();
  const orderbook = state.orderbook;
  const symbol = state.instrument.currentInstrument.symbol;
  if (data.symbol !== symbol) {
    return;
  }

  if (orderbook.isReady) {
    if (orderbook.orderbook.updatedAt === data.lastUpdatedAt) {
      store.dispatch(updateOrderbook(data));
    } else {
      store.dispatch(clearOrderbook());
      store.dispatch(getOrderbook(symbol));
    }
  } else {
    store.dispatch(updateOrderbook(data));
  }
};

const updateRows = (
  rows: string[][],
  newRows: string[][],
  comparator: (n1: string, n2: string) => number
): string[][] => {
  let updatedRows: string[][] = [];

  let rowIndex = 0;
  let newRowIndex = 0;
  while (rowIndex < rows.length && newRowIndex < newRows.length) {
    const price = rows[rowIndex][0];
    const quantity = rows[rowIndex][1];
    const newPrice = newRows[newRowIndex][0];
    const newQuantity = newRows[newRowIndex][1];
    const compareResult = comparator(price, newPrice);
    if (compareResult > 0) {
      // keep old price and quantity
      updatedRows.push([price, quantity]);
      rowIndex++;
    } else if (compareResult === 0) {
      // update quantity
      if (new BigNumber(newQuantity).gt(0)) {
        updatedRows.push([price, newQuantity]);
      }
      rowIndex++;
      newRowIndex++;
    } else {
      // insert new price and quantity
      if (new BigNumber(newQuantity).gt(0)) {
        updatedRows.push([newPrice, newQuantity]);
      }
      newRowIndex++;
    }
  }
  // updatedRows = updatedRows.concat(rows.slice(rowIndex));
  // updatedRows = updatedRows.concat(newRows.slice(newRowIndex)?.filter((row) => new BigNumber(row[1]).gt(0)));
  // return updatedRows;
  
  if (newRows.length < 30) {
    return updatedRows
    .concat(rows.slice(rowIndex))
    .concat(newRows.slice(newRowIndex));
  } else {
    return updatedRows.concat(newRows.slice(newRowIndex));
  }
};

export const applyOrderbookUpdate = (
  orderbook: IOrderbook,
  orderbookUpdate: IOrderbook
): void => {
  orderbook.bids = updateRows(
    orderbook.bids,
    orderbookUpdate.bids,
    (n1: string, n2: string) => new BigNumber(n1).comparedTo(n2)
  ).slice(0, orderbookUpdate.bids.length);
  orderbook.asks = updateRows(
    orderbook.asks,
    orderbookUpdate.asks,
    (n1: string, n2: string) => new BigNumber(n2).comparedTo(n1)
  ).slice(0, orderbookUpdate.asks.length);
  orderbook.updatedAt = orderbookUpdate.updatedAt;
};

export const applyOrderbookUpdates = (
  orderbook: IOrderbook,
  orderbookUpdates: IOrderbook[]
): void => {
  for (const orderbookUpdate of orderbookUpdates) {
    if (orderbookUpdate.updatedAt > orderbook.updatedAt) {
      applyOrderbookUpdate(orderbook, orderbookUpdate);
    }
  }
};

export const roundRows = (
  rows: string[][],
  group: string,
  roundingMode: BigNumber.RoundingMode
): string[][] => {
  const precision = -Math.log10(parseFloat(group));
  const roundPrice = (n: string): string => {
    const quotient = new BigNumber(n).div(group).toFixed(0, roundingMode);
    const rounded = new BigNumber(quotient).times(group);
    return precision > 0 ? rounded.toFixed(precision) : rounded.toFixed(0);
  };
  const rowsFinal = rows?.filter((row) => Number(row[1]) > 0);
  return rowsFinal.map((row) => [roundPrice(row[0]), row[1]]);
};

export const groupRows = (rows: string[][]): string[][] => {
  if (rows.length === 0) {
    return [];
  }

  const groupedRows: string[][] = [];

  let lastPrice = rows[0][0];
  let lastQuantity = rows[0][1];

  const rowCount = rows.length;
  for (let i = 1; i < rowCount; i++) {
    if (lastPrice === rows[i][0]) {
      lastQuantity = new BigNumber(lastQuantity).plus(rows[i][1]).toString();
    } else {
      groupedRows.push([lastPrice, lastQuantity]);
      lastPrice = rows[i][0];
      lastQuantity = rows[i][1];
    }
  }
  groupedRows.push([lastPrice, lastQuantity]);

  return groupedRows;
};

const calculateTotal = (rows: string[][], precision: number): string[][] => {
  let total = new BigNumber("0");
  const rowCount = rows.length;
  for (let i = 0; i < rowCount; i++) {
    total = total.plus(rows[i][1]);
    rows[i][1] = new BigNumber(rows[i][1]).toFixed(precision);
    rows[i].push(total.toFixed(precision));
  }
  return rows;
};

export const calculateTotalAndPercent = (
  bids: string[][],
  asks: string[][],
  precision: number
): IOrderbook => {
  // const precision = -Math.log10(parseFloat(totalPrecision));

  bids = calculateTotal(bids, precision);

  let totalBid = new BigNumber("0");
  if (bids.length > 0) {
    totalBid = new BigNumber(bids[bids.length - 1][2]);
  }

  asks = calculateTotal(asks, precision);
  let totalAsk = new BigNumber("0");
  if (asks.length > 0) {
    totalAsk = new BigNumber(asks[asks.length - 1][2]);
  }

  const total = BigNumber.max(totalBid, totalAsk);
  const addPercent = (row: string[]) =>
    row.push(new BigNumber(row[2]).times(100).div(total).toFixed(2));
  bids.forEach(addPercent);
  asks.forEach(addPercent);

  return { bids, asks, updatedAt: 0 };
};

export const addEmptyRows = (rows: string[][], length: number): string[][] => {
  while (rows.length < length && !rows[0]) {
    rows.push(["\u2002", "", "", "0"]);
  }
  return rows;
};

export const addEmptyRowsMarketTrade = (
  rows: IMarketTrade[],
  length: number
): IMarketTrade[] => {
  const rowTrades = [...rows];
  const emptyRow: IMarketTrade = {
    instrumentSymbol: "--",
    price: "",
    quantity: "",
    buyerIsTaker: false,
    createdAt: "--",
  };
  while (rowTrades.length < length) {
    rowTrades.push(emptyRow);
  }
  return rowTrades;
};
