import React, { useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import styles from 'src/features//Market/components/MarginInformation/MarginInformation.module.scss';
import { SelectCustom } from 'src/components/cores/SelectCustom';
// import { circleIcon } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import Tachometer from 'src/features/Market/components/MarginInformation/Tachometer';
import MarginItemRatioPercent from 'src/features/Market/components/MarginInformation/MarginItemRatioPercent';
import { Transfer } from '../ManageFunds/Transfer';
import { useAppSelector } from 'src/store/hooks';
import { IPosition } from 'src/services/position';
import { formatNumber } from 'src/helpers/numberFormatter';
import PositionCalculator from 'src/helpers/PositionCalculator';
import { ILeverageMargin } from 'src/services/general';
import { USD, USDT } from 'src/features/Market/Constant';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import BigNumber from 'bignumber.js';
import TooltipNumber, { DECIMAL_FOUR } from 'src/components/cores/Tooltip';
import { getMaintenanceMargin, getMarginBalance } from './MarginInforCalculator';
import { Instrument } from 'src/services/instrument';
import { balanceCoin } from 'src/helpers/balanceCalculator';
import { useTranslation } from 'react-i18next';
import { getCookieStorage } from 'src/helpers/storage';
import i18n from 'src/i18n';

const cx = classnames.bind(styles);

type TypeOption = {
  label: string;
  quoteCurrency?: string;
  type?: string;
  symbol?: string;
  value: string;
};

const MarginInformation: React.FC = () => {
  const listPosition = useAppSelector((state) => state.position.allPosition);
  const currentIns: Instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const [precision, setPrecision] = useState<TypeOption>();
  const [openTransfer, setOpenTransfer] = useState(false);
  const [listOption, setListOption] = useState<TypeOption[]>([]);
  const [unreailzedPNL, setUnreailzedPNL] = useState('0.00');
  const [quoteCurrencyAsset, setQuoteCurrency] = useState<string>('');
  const [optionSelected, setOptionSelected] = useState<string>('USDT');

  const balanceFuture = useAppSelector((state) => state.account.account);
  const tickers = useAppSelector((state) => state.ticker.tickers);
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const leverageMargin: ILeverageMargin[] = useAppSelector((state) => state.general.leverageMargin);
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);
  const account = useSelector((state: RootState) => state.account.account);
  const [defaultAsset, setDefaultAsset] = useState('');
  const { t } = useTranslation('common');
  const token = getCookieStorage('access_token');
  const isExpired = getCookieStorage('isExpired');
  const isLogin = isExpired === 'false' && token && token !== 'undefined' && account[0] && account[0].id;

  const handleListAsset = () => {
    const assetFilter = isLogin
      ? account?.filter((item) => {
          const asset = item?.asset?.toUpperCase();
          const coin = asset !== USDT && asset !== USD;
          return isCoinM ? coin : !coin;
        })
      : [];
    const listAsset = isLogin
      ? assetFilter.map((item) => ({ label: item?.asset?.toUpperCase(), value: item?.asset?.toUpperCase() }))
      : [];
    return listAsset;
  };

  const listAsset = handleListAsset();

  useEffect(() => {
    if (isLogin && listPosition.length === 0) {
      setOptionSelected(isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency);
      setQuoteCurrency(isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency);
    }
  }, [listPosition]);

  useEffect(() => {
    setQuoteCurrency(isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency);
    setOptionSelected(isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency);
    setDefaultAsset(isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency);
  }, [currentIns]);

  const handleOptionSelect = () => {
    listOption.splice(0, listOption.length);
    const positionsCross = listPosition?.filter((item) => item.isCross);
    const positionIsolate = listPosition?.filter((item) => !item.isCross);
    if (positionsCross.length > 0) {
      const newPosition = Array.from(new Set(positionsCross.map((item) => item?.asset)));
      // const newSymbol = Array.from(new Set(positionsCross.map((item) => item?.asset)));

      if (isCoinM) {
        positionsCross.map((item) => {
          const asset = item?.asset.toUpperCase();
          const symbol = item?.symbol.toUpperCase();
          listOption.push({
            quoteCurrency: asset,
            symbol: asset,
            value: asset + ' ' + t('trading.cross'),
            label: symbol + ' ' + t('trading.cross'),
            type: 'Cross',
          });
        });
      } else {
        newPosition.map((asset: string) => {
          const assetPosition = asset?.toUpperCase();
          listOption.push({
            quoteCurrency: assetPosition,
            symbol: assetPosition,
            value: assetPosition + ' ' + t('trading.cross'),
            label: assetPosition + ' ' + t('trading.cross'),
            type: 'Cross',
          });
        });
      }
    }

    if (positionIsolate.length > 0) {
      positionIsolate.map((item: IPosition) => {
        const quoteCurrency = instruments?.filter((ins) => ins.symbol === item.symbol);
        listOption.push({
          label: item?.symbol + ' ' + t('order.isolated'),
          quoteCurrency: isCoinM ? quoteCurrency[0]?.rootSymbol : quoteCurrency[0]?.quoteCurrency,
          type: 'Isolate',
          symbol: item?.symbol,
          value: item?.symbol + ' ' + t('order.isolated'),
        });
      });
    }
    setListOption(listOption);
  };

  // Unrealized PNL for each position = Size * (Mark price - Entry Price) * Side
  const handleUnreailzedPNL = (symbol: any, positions: any) => {
    const positionOfSymbol = positions?.filter((item: any) => symbol === item?.asset);
    if (positionOfSymbol.length > 0) {
      let countPNL = 0;
      positionOfSymbol.forEach((item: any) => {
        countPNL = countPNL + Number(PositionCalculator.getUnrealizedPNL(item));
      });
      setUnreailzedPNL(countPNL.toString());
    } else {
      setUnreailzedPNL('0.00');
    }
  };

  useEffect(() => {
    if (listPosition.length > 0) {
      handleOptionSelect();
      handleUnreailzedPNL(quoteCurrencyAsset, listPosition);
      if (!precision) {
        setPrecision(listOption[0]);
      }

      if (precision && precision.type === 'Isolate') {
        const checkHasIsolate = listPosition?.filter((item) => !item.isCross && precision.symbol === item.symbol);
        if (checkHasIsolate.length === 0) {
          setPrecision(listOption[0]);
        }
      }
      if (precision && precision.type === 'Cross' && precision.label.includes(USDT)) {
        const checkHasCrossUSDT = listPosition?.filter((item) => item.isCross && item?.asset === USDT);
        if (checkHasCrossUSDT.length === 0) {
          setPrecision(listOption[0]);
        }
      }
    }
  }, [listPosition, leverageMargin, i18n.language]);

  useEffect(() => {
    if (listPosition.length > 0) {
      setPrecision(listOption[0]);
    }
  }, [i18n.language]);

  const handleChangeTif = (value: any) => {
    setOptionSelected(value);
    handleUnreailzedPNL(value, listPosition);
    setQuoteCurrency(value);
  };

  useEffect(() => {
    if (precision) {
      handleOptionSelect();
      handleUnreailzedPNL(quoteCurrencyAsset, listPosition);
    }
  }, [tickers, leverageMargin, balanceFuture, listPosition]);

  useEffect(() => {
    if (!isLogin || listPosition.length === 0) {
      setUnreailzedPNL('0.00');
    }
  }, [isLogin, listPosition]);

  const marginBalance = getMarginBalance(precision?.type, precision?.quoteCurrency, precision?.symbol);
  const maintenanceMargin = getMaintenanceMargin(precision?.type, precision?.symbol);

  let ratio = 0.0;
  if (new BigNumber(marginBalance).lte(0)) {
    if (new BigNumber(maintenanceMargin).gt(0)) {
      ratio = 100.0;
    } else {
      ratio = 0.0;
    }
  } else {
    ratio = Number(maintenanceMargin) > 0 && isLogin ? (Number(maintenanceMargin) / Number(marginBalance)) * 100 : 0.0;
  }

  const percent = Number(new BigNumber(ratio).toFixed(2, BigNumber.ROUND_DOWN));

  return (
    <div className={cx('wrapper')}>
      <div className={cx('label')}>{t('margin.margin_ratio')}</div>

      <div className={cx('contents')}>
        {/* Margin */}
        <div className={cx('margin-box')}>
          <div className={cx('margin-item')}>
            {listPosition.length > 0 && isLogin ? (
              <SelectCustom
                className={cx('option-precision')}
                value={listOption?.find((e) => e.label === precision?.label)}
                onChange={(e): void => {
                  setPrecision(e);
                }}
                menuPlacement="bottom"
                placeholder={''}
                options={listOption}
                isMargin={true}
              />
            ) : (
              <div className={cx('margin-item-label')}>{t('margin.margin_ratio')}</div>
            )}

            <div className={cx('margin-item-ratio')}>
              {/* <div className={cx('margin-item-ratio-space')}></div> */}
              <div className={cx('margin-item-ratio-right')}>
                <div className={cx('margin-item-ratio-img')}>
                  <Tachometer percent={isLogin && listPosition.length > 0 ? percent : 0} />
                </div>
                <MarginItemRatioPercent
                  percent={
                    isLogin && listPosition.length > 0 ? formatNumber(isNaN(percent) ? 0 : percent, 2, '') : '0.00'
                  }
                />
              </div>
            </div>
          </div>
          <div className={cx('margin-item', 'mt-3')}>
            <div className={cx('margin-item-label')}>{t('margin.maintenance_margin')}</div>
            <div className={cx('margin-item-percent')}>
              <TooltipNumber
                defaultValue={
                  isLogin && listPosition.length > 0 ? getMaintenanceMargin(precision?.type, precision?.symbol) : 0
                }
                decimal={DECIMAL_FOUR}
                characters={11}
                customText={cx('margin-item-percent-label')}
                symbol={precision?.quoteCurrency || (isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency)}
              />
            </div>
          </div>
          <div className={cx('margin-item', 'mt-3')}>
            <div className={cx('margin-item-label')}>{t('margin.margin_balance')}</div>
            <div className={cx('margin-item-percent')}>
              <TooltipNumber
                defaultValue={
                  isLogin ? getMarginBalance(precision?.type, precision?.quoteCurrency, precision?.symbol) : 0
                }
                decimal={DECIMAL_FOUR}
                characters={13}
                customText={cx('margin-item-percent-label')}
                symbol={precision?.quoteCurrency || (isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency)}
              />
            </div>
          </div>

          <div className={cx('margin-item-asset')}>
            <div className={cx('margin-item-label-asset')}>{t('margin.asset')}</div>
            {isLogin && (
              <div className={cx('margin-item-percent')}>
                <SelectCustom
                  className={cx('option-assets')}
                  defaultValue={listAsset[0]}
                  value={listAsset?.find((e) => e.value === optionSelected)}
                  onChange={(e): void => {
                    handleChangeTif(e.label);
                    setDefaultAsset(e.label);
                  }}
                  menuPlacement="bottom"
                  placeholder={''}
                  options={listAsset}
                  isMargin={true}
                />
                &nbsp;
                <span
                  className={cx(!isLogin ? 'margin-item-percent-link-is-login' : 'margin-item-percent-link')}
                  onClick={() => setOpenTransfer(true)}
                >
                  {t('transfer.transfer')}
                </span>
              </div>
            )}
          </div>
          {isLogin && (
            <>
              <div className={cx('margin-item')}>
                <div className={cx('margin-item-label')}>{t('margin.balance')}</div>
                <div className={cx('margin-item-percent')}>
                  <TooltipNumber
                    defaultValue={balanceCoin(quoteCurrencyAsset)?.toString()}
                    decimal={DECIMAL_FOUR}
                    characters={13}
                    customText={cx('margin-item-percent-label')}
                    symbol={quoteCurrencyAsset}
                  />
                </div>
              </div>

              <div className={cx('margin-item', 'mt-3')}>
                <div className={cx('margin-item-label')}>{t('margin.unrealized_pnl')}</div>
                <div className={cx('margin-item-percent')}>
                  <TooltipNumber
                    defaultValue={isLogin && listPosition.length > 0 ? unreailzedPNL : 0}
                    decimal={DECIMAL_FOUR}
                    characters={13}
                    customText={cx('margin-item-percent-label')}
                    symbol={quoteCurrencyAsset}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {openTransfer && (
        <Transfer isOpen={openTransfer} onClose={() => setOpenTransfer(false)} defaultCoinSelect={defaultAsset} />
      )}
    </div>
  );
};

export default MarginInformation;
