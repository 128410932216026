import { DateRange } from '@material-ui/pickers';
import { Pagination } from '@mui/material';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import { noData, noDataDark, iconInfoLight, iconInfoDark, usdmProfile, usdmProfileDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import DatePicker from 'src/features/Orders/DatePickerCustom/DatePicker';
import { SelectFilterSideOrder } from 'src/features/Orders/SelectOrderSide';
import { SelectFilterSymbolOrder } from 'src/features/Orders/SelectOrderSymbol';
import { formatOrderEnum, formatPriceRoundDown } from 'src/helpers/numberFormatter';
import { formatTime } from 'src/helpers/stringFormatter';
import { useWindowSize } from 'src/hooks/useScreen';
import { Instrument } from 'src/services/instrument';
import { getOrderHistory } from 'src/services/order-history';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import OrderTitle from '../OrderTitle';
import { SelectFilterTypeOrder } from '../SelectOrderType/index';
import { fakeDataSide, fakeDataType } from '../constant';
import styles from './index.module.scss';
import BigNumber from 'bignumber.js';
// import { iconArrowDownFilled } from 'src/assets/icon';
import DropdownMenuCustom from 'src/features/Orders/DropdownCustom/DropdownCustom';
import Loading from 'src/components/Loading';
import { THEME_MODE } from 'src/interfaces/theme';
import { RECORDS_PER_PAGE } from '../OpenOrder/OpenOrder';
import { OrderStatusText, OrderType } from 'src/features/PositionAndHistory/constant';
import TooltipNumber, { DECIMAL_TWO } from 'src/components/cores/Tooltip';
import { Box } from '@material-ui/core';
import TooltipText from 'src/components/cores/TooltipText';
import { TypeTrade } from 'src/constants/common.constants';
import { formatOrderText, getSymbolName } from 'src/features/PositionAndHistory/helper';
import { useTranslation } from 'react-i18next';
import TextNumber from 'src/components/cores/TextNumber';
import { OrderStatus } from 'src/interfaces/order';
import { BsFillInboxesFill } from "react-icons/bs";

const cx = classNames.bind(styles);

type dataTableType = {
  accountId?: number;
  activationPrice: string;
  callbackRate: null;
  createdAt: string | number;
  displayQuantity: null;
  executedPrice: string;
  id: string;
  isReduceOnly: boolean;
  leverage: string;
  lockPrice: string;
  marginMode: null;
  note: null;
  operationId: string;
  orderValue: string;
  price: string;
  quantity: string;
  remaining: string;
  side: string;
  status: string;
  stopLossOrderId: string | number;
  symbol: string;
  takeProfitOrderId: string | number;
  timeInForce: string;
  stopPrice: string;
  stopType: string;
  isPostOnly: boolean;
  tpSLPrice: string;
  tpSLType: string;
  stopCondition: string;
  trailValue: null;
  trigger: string;
  type: string;
  updatedAt: string | number;
  vertexPrice: string;
  average?: any;
  parentOrderId?: number | string;
  isClosePositionOrder?: boolean;
  isTpSlOrder?: boolean;
};

const PriceTypeValue = {
  last: 'LAST',
  mark: 'ORACLE',
};

const StatusType = {
  all: null,
  filled: 'FILLED',
  partiallyFilled: 'PARTIALLY_FILLED',
  cancelled: 'CANCELED',
};

const StatusLabel = {
  all: 'order.all',
  filled: 'order.filled',
  partiallyFilled: 'order.partially_filled',
  cancelled: 'order.cancelled',
};

const STATUS_ACTION = [
  { value: StatusType.all, label: StatusLabel.all },
  { value: StatusType.filled, label: StatusLabel.filled },
  { value: StatusType.partiallyFilled, label: StatusLabel.partiallyFilled },
  { value: StatusType.cancelled, label: StatusLabel.cancelled },
];

const OrderHistoryPage: React.FC = () => {
  const { t } = useTranslation('common');
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const dispatch = useAppDispatch();
  const currentInstrument: Instrument | undefined = useAppSelector((state) => state.instrument.currentInstrument);
  const currentUser = useAppSelector((state) => state.account.account);
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const [listCoin, setListCoin] = useState(instruments);
  // const tickers = useAppSelector((state) => state.ticker.tickers);
  const { ordersHistory, loading, totalPage } = useAppSelector((state) => state.orderHistory);

  const [tabActive, setTabActive] = useState<number>(0);
  const [dataTable, setDataTable] = useState<dataTableType[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>([null, null]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isReset, setIsReset] = useState<number>(0);
  const [valueSymbolFilter, setValueSymbolFilter] = useState<string>('');
  const [valueTypeFilter, setValueTypeFilter] = useState<string>('');
  const [valueSideFilter, setValueSideFilter] = useState<string>('');
  const size = useWindowSize();
  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const [statusType, setStatusType] = useState<string>('Status');
  const [totalItem, setTotalItem] = useState<number>(1);
  const tradingRulesCache = useAppSelector((state) => state.masterdataFuture.tradingRulesCache);
  const [tab, setTab] = useState(TypeTrade.USD_M);

  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const [isChangeSelectData, setIsChangeSelectData] = useState<boolean>(false);
  const selectedDateRef = useRef<DateRange<Date>>([null, null]);

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDateRef.current[0]).format('YYYY-MM-DD') !== moment(selectedDate[0]).format('YYYY-MM-DD') ||
      moment(selectedDateRef.current[1]).format('YYYY-MM-DD') !== moment(selectedDate[1]).format('YYYY-MM-DD')
    ) {
      if (selectedDate[0] && selectedDate[1]) {
        setIsChangeSelectData(true);
      } else {
        setIsChangeSelectData(false);
      }
    } else {
      setIsChangeSelectData(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDateRef.current = selectedDate;
  };

  const handleCloseDatePicker = () => {
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDate]);

  const handleChangeDate = (value: DateRange<Date>) => {
    setSelectedDate(value);
  };

  useEffect(() => {
    // if (ordersHistory.length !== 0) {
    //   setTotalItem(totalPage);
    // }
    setTotalItem(totalPage);
  }, [ordersHistory, currentUser[0]?.id]);

  useEffect(() => {
    const list = instruments?.filter((item) =>
      tab === TypeTrade.COIN_M ? item.contractType === TypeTrade.COIN_M : item.contractType === TypeTrade.USD_M,
    );
    setListCoin(list);
  }, [instruments, tab]);

  useEffect(() => {
    history.pushState(tab, '');
  }, [tab]);

  const renderPrice = (order: dataTableType, currentPrice: string) => {
    const curTradingRule = tradingRulesCache?.filter((item) => item.symbol === order?.symbol);
    const maxFiguresForPrice = Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return <TooltipNumber characters={9} decimal={maxFiguresForPrice} defaultValue={currentPrice} />;
  };

  const renderQuantity = (order: dataTableType) => {
    const currentInstrument = listCoin?.find((instrument) => instrument.symbol === order.symbol);
    // return (
    //   <TooltipNumber
    //     defaultValue={order?.quantity}
    //     characters={9}
    //     decimal={tab === TypeTrade.COIN_M ? 0 : 4}
    //     symbol={tab === TypeTrade.COIN_M ? 'Cont' : currentInstrument?.rootSymbol}
    //   />
    // );

    return (
      <TextNumber
        defaultValue={order?.quantity}
        symbol={tab === TypeTrade.COIN_M ? 'Cont' : currentInstrument?.rootSymbol}
        isCoinM={tab === TypeTrade.COIN_M}
      />
    );
  };

  const renderFilledQuantity = (order: dataTableType) => {
    const fillQuantity = new BigNumber(order.quantity ?? '0').minus(order.remaining ?? '0');
    const currentInstrument = listCoin?.find((instrument) => instrument.symbol === order.symbol);

    // return (
    //   <TooltipNumber
    //     defaultValue={fillQuantity}
    //     characters={9}
    //     decimal={tab === TypeTrade.COIN_M ? 0 : 4}
    //     symbol={tab === TypeTrade.COIN_M ? 'Cont' : currentInstrument?.rootSymbol}
    //   />
    // );

    return (
      <TextNumber
        defaultValue={fillQuantity.toString()}
        symbol={tab === TypeTrade.COIN_M ? 'Cont' : currentInstrument?.rootSymbol}
        isCoinM={tab === TypeTrade.COIN_M}
      />
    );
  };

  const renderTriggerConditions = (order: dataTableType) => {
    if (!order.stopCondition) return '-';
    const currentInstrument = listCoin?.find((instrument) => instrument.symbol === order.symbol);
    const tickSize = Number(currentInstrument?.tickSize) || '0.01';
    const precision = -Math.ceil(Math.log10(Number(tickSize)));

    const isTrailingStop = order.tpSLType === OrderType.trailingStop;
    const text = `${t('order.activation_price')}: ${formatPriceRoundDown(order?.activationPrice, precision)}. ${t(
      'order.trigger_type',
    )}: ${order?.trigger === PriceTypeValue.last ? t('order.last_price') : t('order.mark_price')}, ${t(
      'order.callback_rate',
    )}: ${order?.callbackRate ?? '0'}%.`;

    if (order.trigger === PriceTypeValue.last) {
      if (isTrailingStop) {
        return (
          <Box display="flex" alignItems="center">
            {t('order.activation_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.activationPrice} characters={7} decimal={precision} />
            &nbsp;
            <TooltipText text={text}>
              <img src={theme === THEME_MODE.LIGHT ? Icon : Icon} alt="" className={cx('tif-icon')} />
            </TooltipText>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center">
            {t('order.last_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.tpSLPrice} characters={7} decimal={precision} />
          </Box>
        );
      }
    } else if (order.trigger === PriceTypeValue.mark) {
      if (isTrailingStop) {
        return (
          <Box display="flex" alignItems="center">
            {t('order.activation_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.activationPrice} characters={7} decimal={precision} />
            &nbsp;
            <TooltipText text={text}>
              <img src={theme === THEME_MODE.LIGHT ? Icon : Icon} alt="" className={cx('tif-icon')} />
            </TooltipText>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center">
            {t('order.mark_price')} {order.stopCondition === 'GT' ? '>=' : '<='}&nbsp;
            <TooltipNumber defaultValue={order?.tpSLPrice} characters={7} decimal={precision} />
          </Box>
        );
      }
    }
  };

  useEffect(() => {
    if (selectedDate) {
      setIsChangeSelectData(true);
      setCurrentPage(1);
    }
  }, [selectedDate, tab]);

  const handleFilter = () => {
    setCurrentPage(1);
    setIsChangeSelectData(true);
    setIsReset(0);
  };

  useEffect(() => {
    if (currentInstrument?.symbol && currentUser[0]?.id) {
      if (isOpenDatePicker) return;
      if (!isOpenDatePicker && !isChangeSelectData) return;

      dispatch(
        getOrderHistory({
          params: {
            page: currentPage,
            size: RECORDS_PER_PAGE,
          },
          payload: {
            endTime: selectedDate[1]
              ? moment(selectedDate[1]).endOf('day').utc().toDate().getTime()
              : moment().endOf('day').utc().toDate().getTime(),
            startTime: selectedDate[0]
              ? moment(selectedDate[0]).startOf('day').utc().toDate().getTime()
              : moment().subtract(3, 'months').startOf('day').utc().toDate().getTime(),
            side: valueSideFilter && !isReset ? valueSideFilter : null,
            type: valueTypeFilter && !isReset ? valueTypeFilter : null,
            symbol: valueSymbolFilter && !isReset ? valueSymbolFilter : null,
            status: statusType !== 'Status' && !isReset ? statusType : null,
            contractType: tab,
          },
        }),
      );
    }
  }, [
    isOpenDatePicker,
    isChangeSelectData,
    currentInstrument?.symbol,
    currentUser,
    selectedDate,
    valueSymbolFilter,
    valueSideFilter,
    valueTypeFilter,
    // isReset,
    dispatch,
    currentPage,
    statusType,
    tab,
  ]);

  useEffect(() => {
    setDataTable(ordersHistory);
  }, [ordersHistory]);

  const handleChangeStatusType = (value: string) => {
    setStatusType(value);
  };

  const renderTime = (order: dataTableType) => {
    return formatTime(order.updatedAt);
  };

  const renderTypeOrder = (order: dataTableType) => {
    if (order.tpSLType === OrderType.takeProfitMarket) {
      return t('order.take_profit');
    }
    if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
      return t('order.stop_loss');
    }

    if (order.note === 'LIQUIDATION') {
      return t(formatOrderText(order.note));
    }

    return t(formatOrderText(order.tpSLType || order.type));
  };

  const renderAmount = (order: dataTableType) => {
    if (!order.parentOrderId) {
      if (
        order.tpSLType === OrderType.takeProfitMarket ||
        (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
      ) {
        return t('order.close_position');
      }
    }

    return renderQuantity(order);
  };

  const handleAverage = (data: dataTableType) => {
    if (data?.status === OrderStatus.CANCELED && Number(data?.quantity) === Number(data?.remaining)) {
      return '-';
    }

    return renderPrice(data, data?.average ?? '0');
  };

  return (
    <div className={cx('orders-wrapper')}>
      <OrderTitle title={t('order.future_order')} label={t('order.order_history')} />

      <div className={cx('open-order-details')}>
        {/* {TAB_DETAILS.map((item, i) => (
          <div
            key={i}
            className={cx('tab-details', tabActive === i && 'tab-details-active')}
            onClick={() => setTabActive(i)}
          >
            {item}
          </div>
        ))} */}
        <div className={cx('tabs-area')}>
          <div
            onClick={() => {
              setTab(TypeTrade.USD_M);
              setIsReset(1);
            }}
            className={cx('tab-details', tab === TypeTrade.USD_M && 'tab-details-active')}
          >
            USDⓈ-M
          </div>
          <div
            onClick={() => {
              setTab(TypeTrade.COIN_M);
              setIsReset(1);
            }}
            className={cx('tab-details', tab === TypeTrade.COIN_M && 'tab-details-active')}
          >
            COIN-M
          </div>
        </div>

        {tabActive === 0 && (
          <>
            <div className={cx('filter-area')}>
              <div className={cx(size[0] <= 1440 ? 'filter-date-responsive' : 'filter-date')}>
                <DatePicker
                  isReset={isReset}
                  setIsReset={setIsReset}
                  handleChangeDate={handleChangeDate}
                  setIsOpenDatePicker={setIsOpenDatePicker}
                  handleOpenDate={handleOpenDatePicker}
                  handleCloseDate={handleCloseDatePicker}
                />
              </div>

              <div className={cx(size[0] <= 1440 ? 'filter-row-responsive' : 'filter-row')}>
                <SelectFilterSymbolOrder
                  dataList={listCoin}
                  placeHolder={t('order.symbol')}
                  customSelect={cx('width')}
                  className={cx('select-symbol')}
                  setValueSymbolFilter={setValueSymbolFilter}
                  isReset={isReset}
                  handleFilter={handleFilter}
                  label={''}
                  isSearch
                  width={140}
                />
                <SelectFilterTypeOrder
                  dataList={fakeDataType}
                  isReset={isReset}
                  handleFilter={handleFilter}
                  placeHolder={t('order.type')}
                  customSelect={cx('width')}
                  className={cx('select-type')}
                  setValueTypeFilter={setValueTypeFilter}
                  label={''}
                  width={140}
                />
              </div>

              <SelectFilterSideOrder
                isReset={isReset}
                handleFilter={handleFilter}
                dataList={fakeDataSide}
                placeHolder={t('order.side')}
                customSelect={cx('width')}
                className={cx('select-side')}
                setValueSideFilter={setValueSideFilter}
                width={140}
              />
              <div className={cx('filter-reset')}>
                <button
                  className={cx('btn-reset')}
                  onClick={() => {
                    setIsReset(1);
                    setCurrentPage(1);
                  }}
                >
                  {t('order.reset')}
                </button>
              </div>
            </div>
            {
              <div style={{ position: 'relative', maxWidth: 1280 }}>
                <div className={cx('table-content')}>
                  <div
                    className={cx('no-data')}
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '40%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    {!loading && dataTable.length === 0 && (
                      <>
                        <BsFillInboxesFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            style={{ zoom: 5 }}
                          />
                        <div className={cx('no-data-desc')}>{t('order.no_data')}</div>
                      </>
                    )}

                    {loading && <Loading />}
                  </div>

                  <table className={cx('dex_table', totalItem > 1 && 'margin-scrollbar')}>
                    <thead>
                      <tr className={cx('table-header')}>
                        <th className={cx('text-left')}>{t('order.time')}</th>
                        <th className={cx('text-symbol')}>{t('order.symbol')}</th>
                        <th className={cx('text-left')}>{t('order.type')}</th>
                        <th className={cx('')}>{t('order.side')}</th>
                        <th className={cx('text-right')}>{t('order.average')}</th>
                        <th className={cx('text-right')}>{t('order.price')}</th>
                        <th className={cx('text-right')}>{t('order.executed')}</th>
                        <th className={cx('text-right')}>{t('order.amount')}</th>
                        <th>{t('order.trigger_condition')}</th>
                        <th>{t('order.reduceOnly')}</th>
                        <th>{t('order.postOnly')}</th>
                        <th>
                          <div
                            className={cx('type-cancel-order')}
                            onClick={(event: React.MouseEvent<HTMLImageElement>) => {
                              setRefEml(event.currentTarget);
                            }}
                          >
                            <span>{t('order.status')}</span>
                            <img className={cx('icon-down', Boolean(refElm) && 'rotate')} src={Icon} />
                          </div>
                          <DropdownMenuCustom
                            dataTable={dataTable}
                            open={Boolean(refElm)}
                            options={STATUS_ACTION}
                            active={statusType}
                            handleClose={() => setRefEml(null)}
                            refElm={refElm}
                            handleChooseOption={handleChangeStatusType}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody className={cx('dex_table__scrollbar', 'overflow-tbody')} style={{ maxHeight: 210 }}>
                      {loading && <div style={{ height: 340 }}></div>}
                      {!loading && dataTable.length === 0 && <div style={{ height: 340 }}></div>}

                      {!loading && dataTable.length !== 0 && (
                        <>
                          {dataTable.map((data: any, index: number) => (
                            <tr className={cx('row-content')} key={index}>
                              <td className={cx('text-value')}>{renderTime(data)}</td>
                              <td className={cx('text-value')}>
                                <div className={cx('symbol')}>
                                  <div>{getSymbolName(data?.symbol)}</div>
                                  <div className={cx('describe')}>{t('order.perpetual')}</div>
                                </div>
                              </td>
                              <td className={cx('text-value')}>{renderTypeOrder(data)}</td>
                              <td className={cx(data.side == 'BUY' ? 'value-buy' : 'value-sell')}>
                                {data.side ? t(formatOrderText(data?.side)) : '-'}
                              </td>
                              <td className={cx('text-value', 'text-right')}>ccc</td>
                              <td className={cx('text-value', 'text-right')}>
                                {data.price ? renderPrice(data, data?.price ?? '0') : '-'}
                                {/* dddd */}
                              </td>
                              <td className={cx('text-value', 'text-right')}>{renderFilledQuantity(data)}</td>
                              <td className={cx('text-value', 'text-right')}>{renderAmount(data)}</td>
                              <td className={cx('text-value')}>
                                {Number(data.tpSLPrice) || Number(data?.activationPrice)
                                  ? renderTriggerConditions(data)
                                  : '-'}
                                  
                              </td>
                              <td className={cx('text-value')}>{data?.isReduceOnly ? t('order.yes') : t('order.no')}</td>
                              <td className={cx('text-value')}>{data?.isPostOnly ? t('order.yes') : t('order.no')}</td>
                              <td className={cx('text-value')}>
                                {t(formatOrderText(Object(OrderStatusText)[data?.status]))}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {!loading && totalItem > 1 && (
                  <div className={cx('pagination')}>
                    <Pagination
                      page={currentPage}
                      onChange={(e, page) => setCurrentPage(page)}
                      count={totalItem}
                      variant="outlined"
                      shape="rounded"
                      className={cx('pagination-custom')}
                      sx={{
                        '& .MuiPaginationItem-root': {
                          color: 'var(--color-value-1) !important',
                        },
                        '& .Mui-selected': {
                          backgroundColor: 'var(--color-background-pagination) !important',
                          border: '1px solid var(--color-outline-pagination) !important',
                          color: 'var(--color-text-pagination) !important',
                        },
                        '& .MuiPagination-ul': {
                          flexWrap: 'nowrap',
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default OrderHistoryPage;
