import {
  BoxFavoredList,
  DropDownListWrapper,
  FavoredList,
  FavoredOptionDropdown,
  IconOption,
  LabelOptionDropDown,
} from "src/features/TradingViewChart/Components/Chart/styles";
// import { IconBars, IconCandles, IconHollowCandles, IconHeikinAshi, IconLine, IconArea } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useAppSelector } from "src/store/hooks";
import { useTranslation } from "react-i18next";
import { THEME_MODE } from "src/interfaces/theme";

type DropsDownListType = {
  setIsChooseOption: Dispatch<SetStateAction<number>>;
  isChooseOption: number;
};

const DropDownList: React.FC<DropsDownListType> = ({
  setIsChooseOption,
  isChooseOption,
}) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation("common");
  const handleClickedChooseOption = useCallback(
    (e: number) => {
      setIsChooseOption(e);
    },
    [setIsChooseOption]
  );
  return (
    <DropDownListWrapper>
      <BoxFavoredList theme={theme}>
        <FavoredList theme={theme}>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(1)}
          >
            <IconOption>
              <span className="icon icon-bar" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>
              {t("chart_bars")}
            </LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(2)}
          >
            <IconOption>
              <span className="icon icon-heikin-ashi2" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>
              {t("chart_candles")}
            </LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(3)}
          >
            <IconOption>
              <span className="icon icon-heikin-ashi3" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>
              {t("chart_hollow")}
            </LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(4)}
          >
            <IconOption>
              <span className="icon icon-heikin-ashi" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>
              {t("chart_ashi")}
            </LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(5)}
          >
            <IconOption>
              <span className="icon icon-line2" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>
              {t("chart_line")}
            </LabelOptionDropDown>
          </FavoredOptionDropdown>
          <FavoredOptionDropdown
            theme={theme}
            isChooseOption={isChooseOption}
            onClick={() => handleClickedChooseOption(6)}
          >
            <IconOption>
              <span className="icon icon-line3" />
            </IconOption>
            <LabelOptionDropDown theme={theme}>
              {t("chart_area")}
            </LabelOptionDropDown>
          </FavoredOptionDropdown>
        </FavoredList>
      </BoxFavoredList>
    </DropDownListWrapper>
  );
};

export default DropDownList;
