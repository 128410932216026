import { AppBar, Box, Toolbar } from "@material-ui/core";
import classnames from "classnames/bind";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { FaWallet } from "react-icons/fa6";
import { BsSunFill, BsMoonFill } from "react-icons/bs";
// import {
//   apiIcon,
//   CoinIcon,
//   coinMIcon,
//   convertIcon,
//   dashboardIcon,
//   earnIcon,
//   faqIcon,
//   futureIcon,
//   iconMoon,
//   Logo,
//   logoDark,
//   logoMobile,
//   logoutIcon,
//   menuNavbar,
//   menuNavbarDark,
//   noticeIcon,
//   overviewIcon,
//   profileIcon,
//   referralIcon,
//   rewardIcon,
//   savingIcon,
//   securityIcon,
//   spotIcon,
//   stakingIcon,
//   themeIconDark,
//   transactionIcon,
//   USDMIcon,
//   usdmIcon,
// } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import LogoDark from "src/assets/icon/logo-white.svg";
import Logo from "src/assets/icon/logo-dark.svg";
import IconApi from "src/assets/icon/navbar/api_management.svg";
import IconCoin from "src/assets/icon/navbar/coin.svg";
import IconConvert from "src/assets/icon/navbar/convert.svg";
import IconDashboard from "src/assets/icon/navbar/dashboard.svg";
import IconFAQ from "src/assets/icon/navbar/FAQ.svg";
import IconFuture from "src/assets/icon/navbar/future.svg";
import IconLogout from "src/assets/icon/navbar/logout.svg";
import IconNotice from "src/assets/icon/navbar/notice.svg";
import IconWallet from "src/assets/icon/navbar/overview_wallet.svg";
import IconReferral from "src/assets/icon/navbar/referral.svg";
import IconReward from "src/assets/icon/navbar/reward_center.svg";
import IconSecurity from "src/assets/icon/navbar/security.svg";
import IconSpot from "src/assets/icon/navbar/spot.svg";
import IconTransaction from "src/assets/icon/navbar/transaction_history.svg";
import IconUSD from "src/assets/icon/navbar/usd.svg";
import IconUser from "src/assets/icon/navbar/user.svg";

import { useTranslation } from "react-i18next";
import routeConstants from "src/routes/route.constants";
import { getCookieStorage, setOneCookieStorage } from "src/helpers/storage";
import { useAuth } from "src/hooks/useAuth";
import { THEME_MODE } from "src/interfaces/theme";
import { setFavIns } from "src/services/instrument";
import { setTheme } from "src/services/theme";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import SelectBox, { TOption } from "../cores/SelectBox";
import { languages } from "./Constant";
import styles from "./NavBar.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import useOnClickOutside from "src/hooks/useClickOutside";
import SelectBoxMobile, { OPTION_KEY } from "../cores/SelectBoxMobile";
import SelectBoxToggle from "../cores/SelectToggle";
import { handleDirect } from "src/features/PositionAndHistory/PositionRecord";
import useScreen from "src/hooks/useScreen";
import DeviceManagementModal from "../DeviceManagerModal";
import { TypeTrade } from "src/constants/common.constants";
import { updateWeekDays } from "../cores/DatePicker/DatePicker";
import axiosInstanceSpot from "src/helpers/configAPISpot";

const cx = classnames.bind(styles);

type SelectBoxKey =
  | "trade"
  | "wallet"
  | "earn"
  | "order"
  | "support"
  | "futures";
const DOMAIN_SPOT = process.env.REACT_APP_DOMAIN_SPOT || "";

const NavBar: React.FunctionComponent = () => {
  const { login, logout } = useAuth();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation("common");
  const location = useLocation();

  const getLanguage = getCookieStorage("locale") || "en";

  const currentLanguage =
    languages.find((item) => item.value === getLanguage) || languages[0];

  const [languageSeleted, setLanguageSelected] = useState(currentLanguage);
  const account = useSelector((state: RootState) => state.account.account);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const { isDesktop } = useScreen();

  // const [isLoggined, setIsLoggined] = useState(false);
  const [currentSelectBox, setCurrentSelectBox] =
    useState<SelectBoxKey | undefined>();
  const [currentSubMenu, setCurrentSubMenu] =
    useState<OPTION_KEY | undefined>();
  const isExpired = getCookieStorage("isExpired");

  const onSwitchTheme = () => {
    const newTheme =
      theme === THEME_MODE.LIGHT ? THEME_MODE.DARK : THEME_MODE.LIGHT;
    dispatch(setTheme(newTheme));
  };

  const token = getCookieStorage("access_token");
  // console.log('======>',account, token);
  const isAuthenticated =
    isExpired === "false" &&
    token &&
    token !== "undefined" 
    // &&
    // account &&
    // account[0]?.id;

  // console.log("isAuthenticated", isAuthenticated);

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    if (token && token !== "undefined") {
      login(token);
    }
    // console.log(token)
    // console.log(typeof isExpired, isExpired);
    dispatch(setTheme(getCookieStorage("user-theme") || THEME_MODE.LIGHT));
  }, [location.pathname]);

  useEffect(() => {
    i18n.changeLanguage(getLanguage);
    setLanguageSelected(currentLanguage);
  }, [getLanguage]);

  const op: TOption[] = languages.map((op) => ({
    // icon: op.icon,
    label: t(`header.${op.value}`),
    onAction: async () => {
      setOneCookieStorage("locale", op.value);
      setLanguageSelected(op);
      i18n.changeLanguage(op.value);
      updateWeekDays();
      isAuthenticated &&
        (await axiosInstanceSpot.put(`/locale`, {
          lang: op.value,
        }));
    },
  }));

  useEffect(() => {
    if (!isAuthenticated) {
      //   setIsLoggined((account && Object.keys(account).length > 0) || false);
      // } else {
      //   setIsLoggined(false);
      dispatch(setFavIns([]));
    }
  }, [account, token]);

  const el = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  useOnClickOutside(el, () => {
    if (openMenu) {
      setOpenMenu(false);
    }
    setCurrentSelectBox(undefined);
    setCurrentSubMenu(undefined);
  });

  const handleSetDefaultSidebar = useCallback(() => {
    setCurrentSelectBox(undefined);
    setCurrentSubMenu(undefined);
  }, []);

  const handleToggleSelectionBox = useCallback((key: SelectBoxKey) => {
    setCurrentSelectBox((pre) => {
      if (pre === key) return;
      return key;
    });
  }, []);

  const handleToggleSubMenuBox = useCallback((key: OPTION_KEY | undefined) => {
    setCurrentSubMenu((prev) => {
      if (prev === key) return undefined;
      return key;
    });
  }, []);

  useEffect(() => {
    const body = document.getElementById("body");
    openMenu
      ? body?.classList.toggle("is-open")
      : body?.classList.remove("is-open");
  }, [openMenu]);

  return (
    <AppBar position={"static"} className={cx("navbar")}>
      <Toolbar disableGutters={!isDesktop && true} className={cx("tool-bar")}>
        <button
          onClick={handleOpenMenu}
          className={cx("menu-navbar", openMenu && "open-menu")}
          ref={el}
        >
          {/* <img src={theme === THEME_MODE.LIGHT ? Logo : LogoDark} /> */}
          <span
            className={`icon ${openMenu ? "icon-menu-3" : "icon-menu-2"}`}
            style={{
              color: `${theme === THEME_MODE.LIGHT ? "black" : "white"}`,
              zoom: 1.75,
            }}
          />
          {openMenu && (
            <div
              onClick={handleSetDefaultSidebar}
              className={cx("menu-back-drop")}
            >
              <div
                className={cx("menu-options")}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={cx("text-market-mobile")}>
                  <a
                    onClick={async () => {
                      window.location.replace(DOMAIN_SPOT + "/market-overview");
                    }}
                  >
                    {t("header.markets")}
                  </a>
                </div>
                <SelectBoxMobile
                  iconLink
                  title={t("header.trade")}
                  options={[
                    {
                      icon: IconSpot,
                      label: "Spot 321",
                      onAction: async () => {
                        window.location.replace(
                          DOMAIN_SPOT + "/spot-exchange/basic"
                        );
                      },
                    },
                    {
                      icon: IconConvert,
                      label: t("header.convert"),
                      onAction: async () => {
                        window.location.replace(DOMAIN_SPOT + "/convert");
                      },
                    },
                  ]}
                  isOpen={currentSelectBox === "trade"}
                  handleToggleSelection={() =>
                    handleToggleSelectionBox("trade")
                  }
                />
                <SelectBoxMobile
                  iconLink
                  title={`${t("header.futures")}`}
                  options={[
                    {
                      icon: IconUSD,
                      label: "USDⓈ-M",
                      onAction: async () => {
                        !location.pathname.includes(TypeTrade.USD_M) &&
                          window.location.replace(
                            `/futures/${TypeTrade.USD_M}`
                          );
                      },
                    },
                    {
                      icon: IconCoin,
                      label: "COIN-M",
                      onAction: async () => {
                        !location.pathname.includes(TypeTrade.COIN_M) &&
                          window.location.replace(
                            `/futures/${TypeTrade.COIN_M}`
                          );
                      },
                    },
                  ]}
                  isOpen={currentSelectBox === "futures"}
                  handleToggleSelection={() =>
                    handleToggleSelectionBox("futures")
                  }
                />
                {/* <SelectBoxMobile title="Copy Trade" />
                <SelectBoxMobile
                  title="Earn"
                  options={[
                    {
                      icon: earnIcon,
                      label: 'Spot',
                      onAction: async () => {
                        setOpenMenu(false);
                      },
                    },
                    {
                      icon: savingIcon,
                      label: 'Saving',
                      onAction: async () => {
                        setOpenMenu(false);
                      },
                    },
                    {
                      icon: stakingIcon,
                      label: 'Futures',
                      onAction: async () => {
                        setOpenMenu(false);
                      },
                    },
                  ]}
                  isOpen={currentSelectBox === 'earn'}
                  handleToggleSelection={() => handleToggleSelectionBox('earn')}
                  iconLink
                /> */}

                <SelectBoxMobile
                  title={t("header.support")}
                  options={[
                    {
                      icon: IconFAQ,
                      label: "FAQ",
                      onAction: async () => {
                        window.open(
                          "https://monastest-helpdesk.freshdesk.com/support/login"
                        );
                        setOpenMenu(false);
                      },
                    },
                    {
                      icon: IconNotice,
                      label: "Support Center",
                      onAction: async () => {
                        window.open(
                          "https://monastest-helpdesk.freshdesk.com/support/login"
                        );
                        setOpenMenu(false);
                      },
                    },
                  ]}
                  isOpen={currentSelectBox === "support"}
                  handleToggleSelection={() =>
                    handleToggleSelectionBox("support")
                  }
                  iconLink
                />

                {isAuthenticated ? (
                  <>
                    <SelectBoxMobile
                      title={t("header.wallet")}
                      options={[
                        {
                          icon: IconWallet,
                          label: "Overview",
                          onAction: async () => {
                            window.location.replace(
                              DOMAIN_SPOT + "/funds/balances-wallet"
                            );
                            setOpenMenu(false);
                          },
                        },
                        {
                          icon: IconSpot,
                          label: "Spot",
                          onAction: async () => {
                            window.location.replace(
                              DOMAIN_SPOT + "/funds/spot-wallet"
                            );
                            setOpenMenu(false);
                          },
                        },
                        {
                          icon: IconFuture,
                          label: "Futures",
                          onAction: async () => {
                            window.location.replace(
                              DOMAIN_SPOT + "/funds/futures-wallet"
                            );
                            setOpenMenu(false);
                          },
                        },
                        {
                          icon: IconTransaction,
                          label: "Transaction History",
                          onAction: async () => {
                            window.location.replace(
                              DOMAIN_SPOT + "/funds/history-wallet"
                            );
                            setOpenMenu(false);
                          },
                        },
                      ]}
                      isOpen={currentSelectBox === "wallet"}
                      handleToggleSelection={() =>
                        handleToggleSelectionBox("wallet")
                      }
                      iconLink
                    />

                    <SelectBoxMobile
                      title={t("header.order")}
                      handleToggleSubMenu={handleToggleSubMenuBox}
                      currentOption={currentSubMenu}
                      classes="select-box-custom"
                      options={[
                        {
                          icon: IconSpot,
                          label: "Spot",
                          key: "SPOT",
                          subMenu: [
                            {
                              label: t("order.open_orders"),
                              path: async () => {
                                window.location.replace(
                                  `${DOMAIN_SPOT}/spot-exchange/orders/open`
                                );
                                setOpenMenu(false);
                              },
                            },
                            {
                              label: t("order.order_history"),
                              path: async () => {
                                window.location.replace(
                                  `${DOMAIN_SPOT}/spot-exchange/orders/history`
                                );
                                setOpenMenu(false);
                              },
                            },
                            {
                              label: t("order.trade_history"),
                              path: async () => {
                                window.location.replace(
                                  `${DOMAIN_SPOT}/spot-exchange/orders/trade-history`
                                );
                                setOpenMenu(false);
                              },
                            },
                          ],
                        },
                        {
                          icon: IconFuture,
                          label: "Futures",
                          key: "FUTURE",
                          subMenu: [
                            {
                              label: t("order.open_orders"),
                              path: async () => {
                                history.push(
                                  routeConstants.OPEN_ORDER + "?tab=open-order"
                                );
                                setCurrentSelectBox(undefined);
                                setCurrentSubMenu(undefined);
                                setOpenMenu(false);
                              },
                            },
                            {
                              label: t("order.order_history"),
                              path: async () => {
                                history.push(
                                  routeConstants.OPEN_ORDER +
                                    "?tab=order-history"
                                );
                                setCurrentSelectBox(undefined);
                                setCurrentSubMenu(undefined);
                                setOpenMenu(false);
                              },
                            },
                            {
                              label: t("order.trade_history"),
                              path: async () => {
                                history.push(
                                  routeConstants.OPEN_ORDER +
                                    "?tab=trade-history"
                                );
                                setCurrentSelectBox(undefined);
                                setCurrentSubMenu(undefined);
                                setOpenMenu(false);
                              },
                            },
                            {
                              label: t("order.transaction_history"),
                              path: async () => {
                                history.push(
                                  routeConstants.OPEN_ORDER +
                                    "?tab=transaction-history"
                                );
                                // setCurrentSelectBox(undefined);
                                // setCurrentSubMenu(undefined);
                                setOpenMenu(false);
                              },
                            },
                          ],
                        },
                        {
                          icon: IconConvert,
                          label: t("header.convert"),
                          key: "CONVERT",
                          subMenu: [
                            {
                              label: t("order.open_orders"),
                              path: async () => {
                                window.location.replace(
                                  `${DOMAIN_SPOT}/convert/open-order`
                                );
                                setOpenMenu(false);
                              },
                            },
                            {
                              label: t("header.conver_history"),
                              path: async () => {
                                window.location.replace(
                                  `${DOMAIN_SPOT}/convert/history`
                                );
                                setOpenMenu(false);
                              },
                            },
                          ],
                        },
                      ]}
                      isOpen={currentSelectBox === "order"}
                      handleToggleSelection={() =>
                        handleToggleSelectionBox("order")
                      }
                      iconOptions
                    />
                  </>
                ) : (
                  <div className={cx("wrapper-btn-action")}>
                    <button
                      className={cx("btn-sign-up-mb")}
                      onClick={() => handleDirect("signup")}
                    >
                      {t("header.sign_up")}
                    </button>
                    <button
                      className={cx("btn-log-in-mb")}
                      onClick={() => handleDirect("login")}
                    >
                      {t("header.log_in")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </button>
        <Box
          onClick={() => {
            window.location.replace(DOMAIN_SPOT);
            // window.location.replace("/");
          }}
          className={cx("logo")}
        >
          {theme === THEME_MODE.LIGHT ? (
            <img src={Logo} />
          ) : (
            <img src={LogoDark} />
          )}
        </Box>

        <Box
          onClick={() => {
            window.location.replace(DOMAIN_SPOT);
          }}
          className={cx("logo-mb")}
        >
          <img src={Icon} />
        </Box>

        <div className={cx("text-market")}>
          <a
            onClick={async () => {
              window.location.replace(DOMAIN_SPOT + "/market-overview");
            }}
          >
            {t("header.markets")}
          </a>
        </div>

        {/* <SelectBox
          title={t("header.trade")}
          options={[
            {
              icon: IconSpot,
              label: `${t("header.spot")}`,
              href: DOMAIN_SPOT + "/spot-exchange/basic",
            },
            {
              icon: IconConvert,
              label: t("header.convert"),
              href: DOMAIN_SPOT + "/convert",
            },
          ]}
        /> */}
        <div className={cx("text-market")}>
          <a
            onClick={async () => {
              window.location.replace(DOMAIN_SPOT + "/spot-exchange/basic");
            }}
          >
            {t("header.trade")}
          </a>
        </div>
        <SelectBox
          title={`${t("header.futures")}`}
          options={[
            {
              icon: IconUSD,
              label: "USDⓈ-M",
              href: `/futures/${TypeTrade.USD_M}`,
            },
            {
              icon: IconCoin,
              label: "COIN-M",
              href: `/futures/${TypeTrade.COIN_M}`,
            },
          ]}
        />

        {/* <SelectBox title={t('header.copy_trade')} />

        <SelectBox
          title={t('header.earn')}
          options={[
            {
              icon: earnIcon,
              label: `${t('header.earn')}`,
              onAction: async () => {
                window.location.replace(DOMAIN_SPOT + '/spot-exchange/basic');
              },
            },
            {
              icon: savingIcon,
              label: `${t('header.saving')}`,
              onAction: async () => {
                history.push(routeConstants.DASHBOARD);
              },
            },
            {
              icon: stakingIcon,
              label: `${t('header.staking')}`,
              onAction: async () => {
                history.push(routeConstants.DASHBOARD);
              },
            },
          ]}
        /> */}

        <SelectBox
          title={t("header.support")}
          options={[
            {
              icon: IconFAQ,
              label: `${t("header.faq")}`,
              href: `${process.env.REACT_APP_DOMAIN_FRESHDESK}/support/login`,
            },
            {
              icon: IconNotice,
              label: `${t("header.sup_center")}`,
              href: `${process.env.REACT_APP_DOMAIN_FRESHDESK}/support/login`,
            },
          ]}
        />

        <Box flexGrow={15}></Box>

        {isAuthenticated ? (
          <>
            <SelectBoxToggle
              isIcon
              iconTitle={IconUser}
              options={[
                {
                  icon: IconDashboard,
                  label: `${t("header.dashboard")}`,
                  onAction: async () => {
                    window.location.replace(DOMAIN_SPOT + "/account/dashboard");
                  },
                },
                {
                  icon: IconSecurity,
                  label: `${t("header.security")}`,
                  onAction: async () => {
                    window.location.replace(DOMAIN_SPOT + "/account/security");
                  },
                },
                {
                  icon: IconReferral,
                  label: `${t("header.referral")}`,
                  onAction: async () => {
                    window.location.replace(DOMAIN_SPOT + "/account/referral");
                  },
                },
                {
                  icon: IconReward,
                  label: `${t("header.reward_center")}`,
                  onAction: async () => {
                    window.location.replace(
                      DOMAIN_SPOT + "/account/reward-center"
                    );
                  },
                },
                {
                  icon: IconApi,
                  label: `${t("header.api_manage")}`,
                  onAction: async () => {
                    window.location.replace(DOMAIN_SPOT + "/account/api-key");
                  },
                },
                {
                  icon: IconLogout,
                  label: `${t("header.log_out")} sdfadasf`,
                  onAction: async () => {
                    await logout();
                    history.push(routeConstants.DASHBOARD);
                  },
                },
              ]}
            />
            <SelectBox
              title={t("header.wallet")}
              options={[
                {
                  icon: IconWallet,
                  label: `${t("header.over_view")}`,
                  href: DOMAIN_SPOT + "/funds/balances-wallet",
                },
                {
                  icon: IconSpot,
                  label: `${t("header.spot")}`,
                  href: DOMAIN_SPOT + "/funds/spot-wallet",
                },
                {
                  icon: IconFuture,
                  label: `${t("header.futures")}`,
                  href: DOMAIN_SPOT + "/funds/futures-wallet",
                },
                {
                  icon: IconTransaction,
                  label: `${t("header.transaction_history")}`,
                  href: DOMAIN_SPOT + "/funds/history-wallet",
                },
              ]}
            />

            <div className={cx("navbar-item__order")}>
              <SelectBox
                title={t("header.order")}
                options={[
                  {
                    icon: IconSpot,
                    label: `${t("header.spot")}`,
                    href: DOMAIN_SPOT + "/spot-exchange/orders/open",
                  },
                  {
                    icon: IconFuture,
                    label: `${t("header.futures")}`,
                    href: routeConstants.OPEN_ORDER,
                  },
                  {
                    icon: IconConvert,
                    label: t("header.convert"),
                    href: DOMAIN_SPOT + "/convert/open-order",
                  },
                ]}
              />
              <SelectBox
                isIcon
                iconTitle={IconUser}
                options={[
                  {
                    icon: IconDashboard,
                    label: `${t("header.dashboard")}`,
                    href: DOMAIN_SPOT + "/account/dashboard",
                  },
                  {
                    icon: IconSecurity,
                    label: `${t("header.security")}`,
                    href: DOMAIN_SPOT + "/account/security",
                  },
                  {
                    icon: IconReferral,
                    label: `${t("header.referral")}`,
                    href: DOMAIN_SPOT + "/account/referral",
                  },
                  {
                    icon: IconReward,
                    label: `${t("header.reward_center")}`,
                    href: DOMAIN_SPOT + "/account/reward-center",
                  },
                  {
                    icon: IconApi,
                    label: `${t("header.api_manage")}`,
                    href: DOMAIN_SPOT + "/account/api-key",
                  },
                  {
                    icon: IconLogout,
                    label: `${t("header.log_out")}`,
                    onAction: async () => {
                      await logout();
                      // history.push(routeConstants.DASHBOARD);
                    },
                  },
                ]}
              />
            </div>
            <div className={cx("line")}></div>
          </>
        ) : (
          <>
            <button
              className={cx("btn-log-in")}
              onClick={async () => {
                await logout();
                handleDirect("login");
              }}
            >
              {t("header.log_in")}
            </button>
            <button
              className={cx("btn-sign-up")}
              onClick={async () => {
                await logout();
                handleDirect("signup");
              }}
            >
              {t("header.sign_up")}
            </button>
          </>
        )}

        <div className={cx("ml-16")}></div>
        <SelectBoxToggle
          title={t(`header.${languageSeleted.value}`)}
          options={op}
        />
        <div className={cx("mr-16")}></div>

        <SelectBox
          title={t(`header.${languageSeleted.value}`)}
          options={op}
          //  noLink
        />

        {theme === THEME_MODE.LIGHT ? (
          <div className={cx("select_theme")} onClick={onSwitchTheme}>
            {/* <img src={Icon} /> */}
            <BsSunFill fill="black" />
          </div>
        ) : (
          <div className={cx("select_theme")} onClick={onSwitchTheme}>
            {/* <img src={Icon} /> */}
            <BsMoonFill fill="white" />
          </div>
        )}
      </Toolbar>

      {<DeviceManagementModal open={isExpired === "true"} />}
    </AppBar>
  );
};

export default NavBar;
