import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BoxFavoredListTime,
  DropDownListWrapper,
  FavoredList,
  FavoredOption,
  LabelOption,
} from 'src/features/TradingViewChart/Components/Chart/styles';
import { useAppSelector } from 'src/store/hooks';

type ItemDropDownBase = {
  value: string;
  label: string;
  time: string;
};

type DropDownBaseProps = {
  data: ItemDropDownBase[];
  setTime: React.Dispatch<React.SetStateAction<string>>;
  setIsActive: React.Dispatch<React.SetStateAction<string>>;
  time: string;
  isShowFavoredListHours: boolean;
  isShowFavoredListMinutes: boolean;
};

const DropDownBase: React.FC<DropDownBaseProps> = (props) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation('common');
  return (
    <DropDownListWrapper>
      <BoxFavoredListTime>
        <FavoredList theme={theme}>
          {props.data?.map((item: ItemDropDownBase, index) => (
            <FavoredOption theme={theme} key={item.value}>
              <LabelOption
                theme={theme}
                minuteSelected={props.time == props.data[index].value}
                hourSelected={props.time === props.data[index].value}
                onClick={() => {
                  if (props.isShowFavoredListMinutes && props.isShowFavoredListHours === false) {
                    props.setTime(props.data[index].value);
                  }
                  if (props.isShowFavoredListHours && props.isShowFavoredListMinutes === false) {
                    props.setTime(props.data[index].value);
                  }

                  props.setIsActive('');
                }}
              >
                {item.time} {t(item.label)}
              </LabelOption>
            </FavoredOption>
          ))}
        </FavoredList>
      </BoxFavoredListTime>
    </DropDownListWrapper>
  );
};

export default DropDownBase;
