import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import {
//   EnctypeArrowDownIcon,
//   EnctypeArrowUpIcon,
//   IconDot,
//   IconZoomOut,
//   LineChartUpIcon,
//   RoundZoomOutIcon,
//   SettingFilledIcon,
//   iconZoomOutDark,
//   iconZoomOut,
//   iconZoomInDark,
//   iconZoomIn,
//   downFillDark,
//   downFillLight,
// } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { IChartingLibraryWidget } from "src/charting_library/charting_library.min";
import { MOBILE_DIMENSION } from "src/constants/common.constants";
import DropDownBase from "src/features/TradingViewChart/Components/Chart/components/DropDownBase";
import DropDownList from "src/features/TradingViewChart/Components/Chart/components/DropDownList";
import IconBox from "src/features/TradingViewChart/Components/Chart/components/IconBox";
import {
  DEPTH_CHART,
  TRADING_VIEW_CHART,
  dataHours,
  dataMinutes,
  TIME_WEBVIEW,
} from "src/features/TradingViewChart/Components/Chart/constant";
import {
  ChartContent,
  ChartOption,
  ChartType,
  ChartTypeDepth,
  ChartTypeTradingView,
  ChartWrapper,
  DepthOption,
  FullScreen,
  IconRotateFill,
  Label,
  LabelTimeFill,
  Line,
  MoreOptionBox,
  Option,
  OptionCustomHours,
  OptionCustomMinute,
  OptionDay,
  OptionDot,
  OptionMonth,
  OptionsWebview,
  OptionWeek,
  OverLay,
  TimeOption,
  TimeValue,
} from "src/features/TradingViewChart/Components/Chart/styles";
import HighChart from "src/features/TradingViewChart/Components/HighChart";
import TradingView from "src/features/TradingViewChart/Components/TradingView/TradingView";
import useIsMobile from "src/hooks/useIsMobile";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import {
  BsCaretDownFill,
  BsGraphUpArrow,
} from "react-icons/bs";

const ChartIndex: React.FC = () => {
  const [time, setTime] = useState<string>("60");
  const [isActive, setIsActive] = useState<string>("");
  const [isShowFavoredList, setIsShowFavoredList] = useState<boolean>(false);
  const [isShowFavoredListMinute, setIsShowFavoredListMinute] =
    useState<boolean>(false);
  const [isShowFavoredListHours, setIsShowFavoredListHours] =
    useState<boolean>(false);
  const [isChooseOption, setIsChooseOption] = useState<number>(0);
  const [tradingViewChart, setTradingViewChartParent] =
    useState<IChartingLibraryWidget>();
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const optionFull = useRef<HTMLDivElement>(null);
  const [chartActive, setChartActive] = useState(TRADING_VIEW_CHART);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isShowMoreOption, setIsShowMoreOption] = useState<boolean>(false);
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const location = useLocation();
  const { t } = useTranslation("common");

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowMoreOption(false);
          closeAll();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(optionFull);

  const renderLabelMinute = (time: string) => {
    switch (time) {
      case "1":
        return `1 ${t("tradingView.minute_ops")}`;
      case "3":
        return `3 ${t("tradingView.minutes_ops")}`;
      case "5":
        return `5 ${t("tradingView.minutes_ops")}`;
      case "15":
        return `15 ${t("tradingView.minutes_ops")}`;
      case "30":
        return `30 ${t("tradingView.minutes_ops")}`;
      default:
        return `${t("tradingView.minute")}`;
    }
  };

  const renderLabelHour = (time: string) => {
    switch (time) {
      case "60":
        return `1 ${t("tradingView.hour_ops")}`;
      case "120":
        return `2 ${t("tradingView.hours")}`;
      case "240":
        return `4 ${t("tradingView.hours")}`;
      case "360":
        return `6 ${t("tradingView.hours")}`;
      case "720":
        return `12 ${t("tradingView.hours")}`;
      default:
        return `${t("tradingView.hour")}`;
    }
  };

  const handleClickActive = useCallback(
    (e: string) => {
      setIsActive(e);
      setTime("");
    },
    [setIsActive]
  );

  const handleClickFullscreen = useCallback(() => {
    fullScreenRef?.current?.requestFullscreen();
  }, [fullScreenRef?.current]);

  const handleClickExitFullscreen = useCallback(
    (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        document?.exitFullscreen();
      }
    },
    [document]
  );

  const onClose = () => {
    setIsShowFavoredListMinute(false);
    setIsShowFavoredListHours(false);
    setIsShowFavoredList(false);
  };

  const closeAll = () => {
    setIsShowFavoredListMinute(false);
    setIsShowFavoredListHours(false);
    setIsShowFavoredList(false);
  };

  const handleClickShowExpand = () => {
    setIsShowMoreOption(!isShowMoreOption);
    closeAll();
  };

  return (
    <ChartWrapper ref={fullScreenRef}>
      <FullScreen>
        <ChartOption
          theme={theme}
          webview={location.pathname.includes("webview")}
        >
          {chartActive === TRADING_VIEW_CHART && (
            <>
              <TimeOption>
                {(isShowFavoredListMinute ||
                  isShowFavoredListHours ||
                  isShowFavoredList ||
                  isShowMoreOption) && <OverLay onClick={onClose} />}

                {location.pathname.includes("webview") ? (
                  <OptionsWebview>
                    {TIME_WEBVIEW.map((item, index: number) => (
                      <>
                        <TimeValue
                          isActive={time === item.value}
                          onClick={() => {
                            setTime(item.value);
                          }}
                          key={index}
                        >
                          {item.label}
                        </TimeValue>
                        <Line />
                      </>
                    ))}
                    <DepthOption
                      onClick={() => {
                        setChartActive(DEPTH_CHART);
                      }}
                      chartActive={chartActive}
                    >
                      {t("tradingView.depth")}
                    </DepthOption>
                  </OptionsWebview>
                ) : (
                  <>
                    <OptionCustomMinute
                      onClick={() => {
                        closeAll();
                        setIsShowFavoredListMinute(!isShowFavoredListMinute);
                      }}
                    >
                      <LabelTimeFill
                        className={Number(time) < 60 ? "active" : ""}
                      >
                        {renderLabelMinute(time)}
                      </LabelTimeFill>
                      <IconRotateFill
                        isShowFavoredListMinute={isShowFavoredListMinute}
                      >
                        <BsCaretDownFill
                          fill={
                            theme === THEME_MODE.LIGHT ? "black" : "lightgray"
                          }
                          style={{ zoom: 0.75 }}
                        />
                      </IconRotateFill>
                      {isShowFavoredListMinute && (
                        <DropDownBase
                          time={time}
                          isShowFavoredListMinutes={isShowFavoredListMinute}
                          isShowFavoredListHours={isShowFavoredListHours}
                          setIsActive={setIsActive}
                          setTime={setTime}
                          data={dataMinutes}
                        />
                      )}
                    </OptionCustomMinute>
                    <OptionCustomHours
                      onClick={() => {
                        closeAll();
                        setIsShowFavoredListHours(!isShowFavoredListHours);
                      }}
                    >
                      <LabelTimeFill
                        className={Number(time) >= 60 ? "active" : ""}
                      >
                        {renderLabelHour(time)}
                      </LabelTimeFill>
                      <IconRotateFill
                        isShowFavoredListHours={isShowFavoredListHours}
                      >
                        <BsCaretDownFill
                          fill={
                            theme === THEME_MODE.LIGHT ? "black" : "lightgray"
                          }
                          style={{ zoom: 0.75 }}
                        />
                      </IconRotateFill>
                      {isShowFavoredListHours && (
                        <DropDownBase
                          isShowFavoredListMinutes={isShowFavoredListMinute}
                          isShowFavoredListHours={isShowFavoredListHours}
                          time={time}
                          setTime={setTime}
                          data={dataHours}
                          setIsActive={setIsActive}
                        />
                      )}
                    </OptionCustomHours>
                    <OptionDay
                      isActive={isActive}
                      onClick={() => {
                        closeAll();
                        handleClickActive("1D");
                        setTime("1D");
                      }}
                    >
                      <Label theme={theme}>1D</Label>
                    </OptionDay>
                    <OptionWeek
                      isActive={isActive}
                      onClick={() => {
                        closeAll();
                        handleClickActive("1W");
                        setTime("1W");
                      }}
                    >
                      <Label theme={theme}>1W</Label>
                    </OptionWeek>
                    <OptionMonth
                      isActive={isActive}
                      onClick={() => {
                        onClose();
                        handleClickActive("1M");
                        setTime("1M");
                      }}
                    >
                      <Label theme={theme}>1M</Label>
                    </OptionMonth>
                  </>
                )}
                {!isMobile && !location.pathname.includes("webview") ? (
                  <>
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart
                          ?.chart()
                          .executeActionById("insertIndicator");
                      }}
                    >
                      <span className="icon-chart1" />
                    </Option>
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart
                          ?.chart()
                          .executeActionById("chartProperties");
                      }}
                    >
                      <span className="icon-setting" />
                    </Option>
                    <Option
                      onClick={() => {
                        closeAll();
                        setIsShowFavoredList(!isShowFavoredList);
                      }}
                    >
                      <IconBox
                        tradingViewChart={tradingViewChart}
                        isShowFavoredList={isShowFavoredList}
                        isChooseOption={isChooseOption}
                      />
                      {isShowFavoredList && (
                        <DropDownList
                          isChooseOption={isChooseOption}
                          setIsChooseOption={setIsChooseOption}
                        />
                      )}
                    </Option>
                    {/* <Option>
                      <RoundZoomOutIcon
                        className="iconRoundZoomOutIcon"
                        onClick={() => {
                          onClose();
                          handleClickFullscreen();
                        }}
                        onKeyDown={handleClickExitFullscreen}
                      />
                    </Option> */}
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart?.saveChartToServer();
                      }}
                    >
                      <span
                        className="icon icon-upload"
                        style={{ opacity: 3 }}
                      />
                    </Option>
                    <Option
                      onClick={() => {
                        onClose();
                        tradingViewChart?.showLoadChartDialog();
                      }}
                    >
                      <span className="icon icon-download" />
                    </Option>
                    <ChartType>
                      <ChartTypeTradingView
                        onClick={() => {
                          setChartActive(TRADING_VIEW_CHART);
                          onClose();
                        }}
                        chartActive={chartActive}
                      >
                        {t("tradingView.trading_view")}
                      </ChartTypeTradingView>
                      <ChartTypeDepth
                        onClick={() => {
                          setChartActive(DEPTH_CHART);
                          onClose();
                        }}
                        chartActive={chartActive}
                      >
                        {t("tradingView.depth")}
                      </ChartTypeDepth>

                      {isFullscreen ? (
                        <span
                          className="icon icon-exit-full-screen"
                          style={{
                            cursor: "pointer",
                            zoom: 1.75,
                            marginTop: "3px",
                          }}
                          onClick={() => {
                            setIsFullscreen(!isFullscreen);
                            document.exitFullscreen();
                          }}
                          onKeyDown={handleClickExitFullscreen}
                        />
                      ) : (
                        <span
                          className="icon icon-expad"
                          style={{
                            cursor: "pointer",
                            zoom: 1.5,
                            marginTop: "3px",
                          }}
                          onClick={() => {
                            setIsFullscreen(!isFullscreen);
                            onClose();
                            handleClickFullscreen();
                          }}
                          onKeyDown={handleClickExitFullscreen}
                        />
                      )}
                    </ChartType>
                  </>
                ) : (
                  <>
                    {!location.pathname.includes("webview") && (
                      <OptionDot>
                        <BsGraphUpArrow
                          fill={
                            theme === THEME_MODE.LIGHT ? "black" : "lightgray"
                          }
                          onClick={handleClickShowExpand}
                        />

                        {isShowMoreOption && (
                          <MoreOptionBox theme={theme} ref={optionFull}>
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart
                                  ?.chart()
                                  .executeActionById("insertIndicator");
                              }}
                            >
                              <span className="icon icon-chart1" />
                            </Option>
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart
                                  ?.chart()
                                  .executeActionById("chartProperties");
                              }}
                            >
                              <span className="icon icon-setting" />
                            </Option>
                            <Option
                              onClick={() => {
                                closeAll();
                                setIsShowFavoredList(!isShowFavoredList);
                              }}
                            >
                              <IconBox
                                tradingViewChart={tradingViewChart}
                                isShowFavoredList={isShowFavoredList}
                                isChooseOption={isChooseOption}
                              />
                              {isShowFavoredList && (
                                <DropDownList
                                  isChooseOption={isChooseOption}
                                  setIsChooseOption={setIsChooseOption}
                                />
                              )}
                            </Option>
                            {/* <Option>
                              <RoundZoomOutIcon
                                onClick={() => {
                                  onClose();
                                  handleClickFullscreen();
                                }}
                                onKeyDown={handleClickExitFullscreen}
                              />
                            </Option> */}
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart?.saveChartToServer();
                              }}
                            >
                              <span className="icon icon-upload" />
                            </Option>
                            <Option
                              onClick={() => {
                                onClose();
                                tradingViewChart?.showLoadChartDialog();
                              }}
                            >
                              <span className="icon icon-download" />
                            </Option>
                            <ChartType width={isMobile}>
                              <ChartTypeTradingView
                                onClick={() => {
                                  setChartActive(TRADING_VIEW_CHART);
                                  onClose();
                                }}
                                chartActive={chartActive}
                              >
                                Trading View
                              </ChartTypeTradingView>
                              <ChartTypeDepth
                                onClick={() => {
                                  setChartActive(DEPTH_CHART);
                                  onClose();
                                  setIsShowMoreOption(false);
                                  closeAll();
                                }}
                                chartActive={chartActive}
                              >
                                Depth
                              </ChartTypeDepth>
                              <span
                                className="icon icon-expad"
                                style={{
                                  cursor: "pointer",
                                  zoom: 1.25,
                                  marginTop: "3px",
                                }}
                                onClick={() => {
                                  setIsFullscreen(!isFullscreen);
                                  onClose();
                                  handleClickFullscreen();
                                }}
                                onKeyDown={handleClickExitFullscreen}
                              />
                            </ChartType>
                          </MoreOptionBox>
                        )}
                      </OptionDot>
                    )}
                  </>
                )}
              </TimeOption>
            </>
          )}
          {chartActive === DEPTH_CHART && (
            <TimeOption>
              {location.pathname.includes("webview") ? (
                <OptionsWebview>
                  {TIME_WEBVIEW.map((item, index: number) => (
                    <>
                      <TimeValue
                        onClick={() => {
                          setChartActive(TRADING_VIEW_CHART);
                          setTime(item.value);
                        }}
                        key={index}
                      >
                        {item.label}
                      </TimeValue>
                      <Line />
                    </>
                  ))}
                  <DepthOption
                    onClick={() => {
                      setChartActive(DEPTH_CHART);
                    }}
                    chartActive={chartActive}
                  >
                    {t("tradingView.depth")}
                  </DepthOption>
                </OptionsWebview>
              ) : (
                <ChartType>
                  <ChartTypeTradingView
                    onClick={() => {
                      setChartActive(TRADING_VIEW_CHART);
                      onClose();
                    }}
                    chartActive={chartActive}
                  >
                    {t("tradingView.trading_view")}
                  </ChartTypeTradingView>
                  <ChartTypeDepth
                    onClick={() => {
                      setChartActive(DEPTH_CHART);
                      onClose();
                    }}
                    chartActive={chartActive}
                  >
                    {t("tradingView.depth")}
                  </ChartTypeDepth>
                  {isFullscreen ? (
                    <span
                      className="icon icon-exit-full-screen"
                      style={{
                        cursor: "pointer",
                        zoom: 1.75,
                        marginTop: "3px",
                      }}
                      onClick={() => {
                        setIsFullscreen(!isFullscreen);
                        document.exitFullscreen();
                      }}
                      onKeyDown={handleClickExitFullscreen}
                    />
                  ) : (
                    <span
                      className="icon icon-expad"
                      style={{
                        cursor: "pointer",
                        zoom: 1.25,
                        marginTop: "3px",
                      }}
                      onClick={() => {
                        setIsFullscreen(!isFullscreen);
                        onClose();
                        handleClickFullscreen();
                      }}
                      onKeyDown={handleClickExitFullscreen}
                    />
                  )}
                </ChartType>
              )}
            </TimeOption>
          )}
        </ChartOption>
        <ChartContent>
          {chartActive === TRADING_VIEW_CHART && (
            <TradingView
              valueInterval={time}
              setTradingViewChartParent={setTradingViewChartParent}
              containerId={"trading-view-chart"}
              clientId="vcc"
            />
          )}
          {chartActive === DEPTH_CHART && <HighChart />}
        </ChartContent>
      </FullScreen>
    </ChartWrapper>
  );
};

export default ChartIndex;
