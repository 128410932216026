import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';
import { getLocalStorage, setLocalStorage } from 'src/helpers/storage';

interface Ileverage {
  maxLeverage: number;
  max: string;
  min: string;
  maintenanceAmount: string;
  maintenanceMarginRate: number;
  tier: number;
  instrumentId: any;
  symbol: string;
}
interface ITradingRuleCache {
  maxLeverage: number;
  symbol: string;
  minOrderAmount: string;
  minPrice: string;
  maxOrderAmount: string;
  maxPrice: string;
  maxFiguresForPrice: string;
  maxFiguresForSize: string;
  id: number;
  instrumentId: number;
  minTradeAmount: string;
  limitOrderPrice: string;
  floorRatio: string;
  maxMarketOrder: string;
  limitOrderAmount: string;
  numberOpenOrders: string;
  priceProtectionThreshold: string;
  liqClearanceFee: string;
  minNotional: string;
  marketOrderPrice: string;
  isReduceOnly: boolean;
  positionsNotional: string;
  ratioOfPostion: string;
  liqMarkPrice: string;
  createdAt: string;
  updatedAt: string;
}

interface ICoinInfo {
  id: string;
  fullName: string;
  baseId: string;
  symbol: string;
  rank: number;
  marketCap: string;
  cirSupply: string;
  maxSupply: string;
  totalSupply: string;
  issueDate: string;
  issuePrice: string;
  explorer: string;
  coin_image: string;
  createdAt: string;
  updatedAt: string;
}

const initialState: {
  loading: boolean;
  error: boolean;
  leverageMarginCache: Ileverage[];
  tradingRulesCache: ITradingRuleCache[];
  coinInfo: ICoinInfo[];
} = {
  loading: false,
  error: false,
  leverageMarginCache: [
    {
      maxLeverage: 0,
      max: '',
      min: '',
      maintenanceAmount: '',
      maintenanceMarginRate: 0,
      tier: 0,
      symbol: '',
      instrumentId: [],
    },
  ],
  tradingRulesCache: [
    {
      maxLeverage: 0,
      symbol: '',
      minOrderAmount: '0',
      minPrice: '0',
      maxOrderAmount: '0',
      maxPrice: '0',
      maxFiguresForPrice: '0',
      maxFiguresForSize: '0',
      createdAt: '',
      floorRatio: '',
      id: 0,
      instrumentId: 0,
      isReduceOnly: false,
      limitOrderAmount: '',
      limitOrderPrice: '',
      liqClearanceFee: '',
      liqMarkPrice: '',
      marketOrderPrice: '',
      maxMarketOrder: '',
      minNotional: '',
      minTradeAmount: '',
      numberOpenOrders: '',
      positionsNotional: '',
      priceProtectionThreshold: '',
      ratioOfPostion: '',
      updatedAt: '',
    },
  ],
  coinInfo: [
    {
      id: '',
      fullName: '',
      baseId: '',
      symbol: '',
      rank: 1,
      marketCap: '',
      cirSupply: '',
      maxSupply: '',
      totalSupply: '',
      issueDate: '',
      issuePrice: '',
      explorer: '',
      coin_image: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
};

export const getMasterDataFuture = createAsyncThunk('masterdataFuture', async () => {
  const res = await axiosInstance.get('/master-data');
  return res;
});

export const masterdataFutureSlice = createSlice({
  name: 'masterdataFuture',
  initialState,
  reducers: {},
  extraReducers: {
    [getMasterDataFuture.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getMasterDataFuture.rejected.toString()]: (state, action: any) => {
      state.loading = false;
      state.error = action.error;
      const masterdata: any = getLocalStorage('masterdataFuture') || null;
      if (masterdata) {
        state.leverageMarginCache = masterdata.leverageMargin;
        state.tradingRulesCache = masterdata.tradingRules;
        state.coinInfo = masterdata.coinInfo;
      }
    },
    [`${getMasterDataFuture.fulfilled}`]: (state, action) => {
      state.loading = false;
      setLocalStorage('masterdataFuture', action.payload.data || []);
      state.leverageMarginCache = action.payload.data?.leverageMargin || [];
      state.tradingRulesCache = action.payload.data?.tradingRules || [];
      state.coinInfo = action.payload.data?.coinInfo || [];
    },
  },
});

const { reducer: masterdataFutureReducer } = masterdataFutureSlice;

export default masterdataFutureReducer;
