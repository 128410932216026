import { Box, Checkbox, styled } from '@material-ui/core';
import classnames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'src/features/PositionAndHistory/styles/HistoryDashboard.module.scss';
import { getCookieStorage } from 'src/helpers/storage';
import { THEME_MODE } from 'src/interfaces/theme';
import { useAppSelector } from 'src/store/hooks';
import Assets from './Assets';
import { listTab } from './constant';
import OpenOrder from './OpenOrder';
import OrderHistory from './OrderHistory';
import PositionRecord from './PositionRecord';
import TradeHistory from './TradeHistory';
import TransactionHistory from './TransactionHistory';

const cx = classnames.bind(styles);

const HistoryDashboard: React.FC = () => {
  const [tab, setTab] = useState<number>(0);
  const [hideOtherPairsChecked, setHideOtherPairsChecked] = useState<boolean>(false);
  const [hideAssetChecked, setHideAssetChecked] = useState<boolean>(false);
  const [listTabs, setListTabs] = useState(listTab);
  const allPosition = useAppSelector((state) => state.position.allPosition);
  const allOrders = useAppSelector((state) => state.order.allOrders);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const account = useAppSelector((state) => state.account.account);
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);
  const token = getCookieStorage('access_token');
  const isExpired = getCookieStorage('isExpired');
  const isAuthenticated = isExpired === 'false' && token && token !== 'undefined' 
  // && account && account[0]?.id;
  const { t } = useTranslation('common');

  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTicker = useAppSelector((state) => state.ticker.loading);
  const loadingTradingRules = useAppSelector((state) => state.masterdataFuture.loading);

  const renderValue = (value: number | undefined) => {
    if (!value) return '';
    if (!isAuthenticated || loadingInstrument || loadingTicker || loadingTradingRules) return 0;
    if (value === 1) return allPosition.length;
    if (value === 2) return allOrders.length;
  };

  const handleChange = (event: any) => {
    if (tab !== 4) {
      if (tab === 5) {
        setHideAssetChecked(event.target.checked);
      } else {
        setHideOtherPairsChecked(event.target.checked);
      }
    }
  };

  useEffect(() => {
    if (!isCoinM) setListTabs(listTab.slice(0, 5));
    else setListTabs(listTab);
  }, [isCoinM, allOrders]);

  const BpIcon = styled('span')(() => ({
    borderRadius: 2,
    width: 19,
    height: 19,
    backgroundColor: theme === THEME_MODE.LIGHT ? '#DEDEDE' : '#424754',

    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#00DDB3',
    '&:before': {
      display: 'block',
      width: 19,
      height: 19,
      backgroundImage:
        // eslint-disable-next-line max-len
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        // eslint-disable-next-line max-len
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23333'/%3E%3C/svg%3E\")",
      content: '""',
    },
  });

  return (
    <Box>
      <div className={cx('container')}>
        <div className={'height-100'}>
          <div className={cx('box-container')}>
            <div className={cx('tab-wrapper')}>
              {listTabs.map((item: { label: string; value?: number }, index: number) => {
                return (
                  <div
                    key={item?.label}
                    onClick={() => setTab(index)}
                    className={tab === index ? cx('tab', 'active') : cx('tab')}
                  >
                    {`${t(item?.label)} ${renderValue(item?.value) !== '' ? '(' + renderValue(item?.value) + ')' : ''}`}
                  </div>
                );
              })}
            </div>
            {tab !== 4 ? (
              <div className={cx('hidden-btn')}>
                <label className={cx('container')}>
                  <input
                    type="checkbox"
                    checked={tab === 5 ? hideAssetChecked : hideOtherPairsChecked}
                    onChange={handleChange}
                  />
                  <span className={cx('checkmark')}></span>
                </label>
                <span className={cx('hidden-text')}>
                  {tab === 5 ? t('order.hide_asset') : t('order.hide_other_pair')}
                </span>
              </div>
            ) : (
              <div style={{ height: 35 }}></div>
            )}
          </div>

          <div className={'height-100'}>
            {tab === 0 && <PositionRecord hideOtherPairs={hideOtherPairsChecked} />}
            {tab === 1 && <OpenOrder hideOtherPairs={hideOtherPairsChecked} />}
            {tab === 2 && <OrderHistory hideOtherPairs={hideOtherPairsChecked} />}
            {tab === 3 && <TradeHistory hideOtherPairs={hideOtherPairsChecked} />}
            {tab === 4 && <TransactionHistory />}
            {tab === 5 && <Assets hideAsset={hideAssetChecked} />}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default HistoryDashboard;
