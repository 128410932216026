import React, { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import {
//   IConDownDefault,
//   iconCloseDark,
//   iconCloseValueInput,
//   iconSearchSelect,
//   searchIconDark,
//   downFilterLight,
//   downFilterDark,
// } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import useOnClickOutside from 'src/hooks/useClickOutside';
import styled from '@emotion/styled';
import { useAppSelector } from 'src/store/hooks';
import useScreen, { useWindowSize } from 'src/hooks/useScreen';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
// import { Instrument } from 'src/services/instrument';
// import CancelAllOrderModal from '../CancelAllOrderModal';
// import { withdraw } from '../../../services/withdraw';
import { BsSearch,BsFillXOctagonFill, BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";

type Props = {
  isSearch?: boolean;
  dataList: any;
  placeHolder?: any;
  isCancel?: any;
  customSelect?: any;
  className?: any;
  setValueSymbolFilter: Dispatch<SetStateAction<string>>;
  isReset: number;
  label: string;
  setIsReset?: Dispatch<SetStateAction<number>>;
  width: number;
  handleFilter?: any
};

const cx = classnames.bind(styles);

type SelectType = {
  customSelect?: any;
  openSelect?: boolean;
  placeHolder?: any;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  optionSelect?: any;
  theme: string;
  isMobile?: boolean;
  size: number;
  width: number;
  label: string;
};

type SelectWrapperType = {
  theme: string;
  isMobile?: boolean;
  size: number;
  width: number;
};

type SelectFilterActionType = {
  theme: string;
  size: number;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
};

type SelectFilterType = {
  theme: string;
  size: number;
};

const FilterAction = styled.div<SelectFilterActionType>`
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#ffffff' : '#333333')};
  display: flex;
  & .iconDown {
    path {
      stroke: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#ffffff')};
    }
  }
`;

const SelectValueWrapper = styled.div<SelectWrapperType>`
  background: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#f8f8f8' : '#1B1D24')};
  border-radius: 8px;
  padding: 14px 15px;
  width: ${(props) => (props.isMobile || props.size <= 970 ? 'auto' : `${props.width}px`)};
  min-width: ${(props) => (props.isMobile || props.size <= 970 ? 'auto' : `${props.width}px`)};
  display: flex;
  gap: 5px;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile || props.size <= 970 ? '14px' : '18px')};
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6d7387')};
  cursor: pointer;
  justify-content: space-between;
  user-select: none;
`;

const PlaceHolder = styled.div<SelectFilterType>`
  display: block;
  font-size: ${(props) => (props.size <= 970 ? '14px' : '18px')};
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6D7387')};
  white-space: nowrap;
`;

const FilterName = styled.div<SelectFilterType>`
  width: auto;
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#ffffff')};
`;

const FilterIcon = styled.div`
  margin-left: 9px;
`;

const fn = (text: string, count: number) => {
  return text?.slice(0, count) + (text?.length > count ? '...' : '');
};

const SelectValue = (props: SelectType) => {
  const { t } = useTranslation('common');
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  return (
    <SelectValueWrapper
      theme={props.theme}
      isMobile={props.isMobile}
      onClick={() => props.setOpenSelect(!props.openSelect)}
      size={props.size}
      width={props.width}
    >
      <PlaceHolder size={props.size} theme={props.theme}>
        {props.size === 280 ? fn(props.placeHolder, 2) : props.placeHolder}
      </PlaceHolder>
      <FilterAction
        size={props.size}
        setOpenSelect={props.setOpenSelect}
        theme={props.theme}
      >
        <FilterName size={props?.size} theme={props?.theme}>
          {props.label === "Open Orders" ? (
            <div className={cx("filter-open-order")}>
              {t(props?.optionSelect?.name)}
            </div>
          ) : (
            <div className={cx("name-filter")}>
              {t(props?.optionSelect?.name)}
            </div>
          )}
        </FilterName>
        <FilterIcon>
          {props.openSelect ? (
            <BsCaretUpFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.2 }}
            />
          ) : (
            <BsCaretDownFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.2 }}
            />
          )}
        </FilterIcon>
      </FilterAction>
    </SelectValueWrapper>
  );
};

const SelectValueNoFirstWrapper = styled.div<SelectWrapperType>`
  border-radius: 8px;
  padding: 14px 0px;
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile || props.size <= 970 ? '14px' : '18px')};
  gap: 10px;
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6d7387')};
  cursor: pointer;
  justify-content: flex-end;
  user-select: none;
  margin-left: 22px;
`;

const SelectValueNoFirst = (props: SelectType) => {
  const { t } = useTranslation('common');
  return (
    <SelectValueNoFirstWrapper
      size={props.size}
      theme={props.theme}
      onClick={() => props.setOpenSelect(!props.openSelect)}
      width={props.width}
    >
      <PlaceHolder size={props.size} theme={props.theme}>
        {t(props.placeHolder)}
      </PlaceHolder>
      <FilterIcon theme={props.theme}>
        {/* <IConDownDefault className="iconDown" /> */}
        {IconSVG}
      </FilterIcon>
    </SelectValueNoFirstWrapper>
  );
};

export const SelectFilterSymbolOrder: React.FC<Props> = ({
  dataList,
  isSearch,
  placeHolder,
  isCancel,
  customSelect,
  className,
  setValueSymbolFilter,
  isReset,
  label,
  setIsReset,
  handleFilter,
  width,
}) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { isMobile } = useScreen();
  const [openSelect, setOpenSelect] = useState(false);
  const [optionSelect, setOptionSelect] = useState({ name: 'order.all', value: '', symbol: '' });
  const el = useRef(null);
  const [textSearch, setTextSearch] = useState('');
  const [dataSelect, setDataSelect] = useState(dataList);
  const size = useWindowSize();
  const { t } = useTranslation('common');

  useEffect(() => {
    setDataSelect(dataList);
  }, [dataList]);

  useEffect(() => {
    if (isReset !== 0) {
      setValueSymbolFilter('');
      setOptionSelect({ name: 'order.all', value: '', symbol: '' });
    }
  }, [dataList, isReset, setValueSymbolFilter]);

  const handleSearch = (text: string) => {
    setTextSearch(text);

    const resultSearch = dataList?.filter(
      (item: any) => item.name && item.name.toLowerCase().includes(text.toLowerCase()),
    );
    setDataSelect(resultSearch);
  };

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
    setTextSearch('');
    setDataSelect(dataList);
  });

  return (
    <div className={cx("select-wrapper", className)} ref={el}>
      {!isCancel ? (
        <SelectValue
          setOpenSelect={setOpenSelect}
          customSelect={customSelect}
          openSelect={openSelect}
          placeHolder={placeHolder}
          optionSelect={optionSelect}
          theme={theme}
          isMobile={isMobile}
          size={size[0]}
          width={width}
          label={label}
        />
      ) : (
        <SelectValueNoFirst
          width={width}
          size={size[0]}
          theme={theme}
          openSelect={openSelect}
          setOpenSelect={setOpenSelect}
          label={label}
        />
      )}

      {openSelect && (
        <div
          className={cx(!label ? "select-list" : "select-list-custom")}
          // style={{ width: size[0] <= 970 || label === 'Open Orders' ? '100%' : '243px', right: '0' }}
        >
          {isSearch && (
            <div style={{ padding: "15px 15px 0px 15px" }}>
              <div className={cx("select-search")}>
                <input
                  placeholder={t("search_symbol")}
                  value={textSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {textSearch && (
                  // <img
                  //   src={theme === THEME_MODE.LIGHT ? Icon : Icon}
                  //   onClick={() => {
                  //     setTextSearch('');
                  //     setDataSelect(dataList);
                  //   }}
                  //   style={{ cursor: 'pointer' }}
                  // />
                  <BsFillXOctagonFill
                    fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                    style={{ zoom: 1.2, cursor: "pointer" }}
                    onClick={() => {
                      setTextSearch("");
                      setDataSelect(dataList);
                    }}
                  />
                )}
                <BsSearch
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 1.2, cursor: "pointer" }}
                />
              </div>
            </div>
          )}
          {!textSearch && (
            <div
              className={cx(
                "select-item",
                optionSelect?.symbol === "" && "select-item-active"
              )}
              onClick={() => {
                setOptionSelect({ name: "order.all", value: "", symbol: "" });
                setOpenSelect(false);
                setValueSymbolFilter("");
                handleFilter();
              }}
              style={{ cursor: "pointer" }}
            >
              {t("order.all")}
            </div>
          )}

          {dataSelect.length > 0 ? (
            dataSelect?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  {!isCancel ? (
                    <div
                      className={cx(
                        "select-item",
                        item.symbol === optionSelect?.symbol &&
                          "select-item-active"
                      )}
                      onClick={() => {
                        setOptionSelect(item);
                        setOpenSelect(false);
                        setValueSymbolFilter(item.symbol);
                        handleFilter();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {item.name}
                    </div>
                  ) : (
                    <>
                      <div
                        className={cx("select-item")}
                        onClick={() => {
                          setOpenSelect(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </div>
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <div className={cx("no-result")}>{t("pair.no_result_found")}</div>
          )}
        </div>
      )}
    </div>
  );
};
