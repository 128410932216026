import React from 'react';
import styled from '@emotion/styled';
// import { clockwiseIcon, iconRatio } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import { BsExclamationCircleFill } from "react-icons/bs";

type Props = {
  percent: number;
};

const TachometerWrapper = styled.div<Props>`
  position: absolute;
  top: 1px;
  left: -20px;
  transform: translate(0%, 50%) rotate(${(props) => props.percent * 2.4}deg);
  z-index: 1;
`;

const Tachometer: React.FC<Props> = ({ percent }) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  return (
    <TachometerWrapper
      percent={isNaN(percent) ? 0 : percent > 100 ? 100 : percent}
    >
      <BsExclamationCircleFill
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
      />
    </TachometerWrapper>
  );
};

export default Tachometer;
