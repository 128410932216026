import BigNumber from "bignumber.js";
import classnames from "classnames/bind";
import React, { useEffect, useState } from "react";
// import { iconUSDT } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import Loading from "src/components/Loading";
import styles from "src/features/PositionAndHistory/styles/Order.module.scss";
import { getAvailableBalance } from "src/helpers/balanceCalculator";
import PositionCalculator from "src/helpers/PositionCalculator";
import { getCookieStorage } from "src/helpers/storage";
import { useAppSelector } from "src/store/hooks";
import { Transfer } from "../Market/components/ManageFunds/Transfer";
import { handleDirect } from "./PositionRecord";
import { USD, USDT } from "../Market/Constant";
import TooltipNumber from "src/components/cores/Tooltip";
import { useTranslation } from "react-i18next";
import { THEME_MODE } from "src/interfaces/theme";
// import { noData, noDataDark } from 'src/assets/icon';
import TextNumber from "src/components/cores/TextNumber";
import { BsInboxesFill, BsCoin } from "react-icons/bs";

const cx = classnames.bind(styles);

const Assets = (props: any) => {
  const { hideAsset } = props;
  const { account, loading } = useAppSelector((state) => state.account);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [defaultAsset, setDefaultAsset] = useState("");
  const token = getCookieStorage("access_token");
  const isExpired = getCookieStorage("isExpired");
  const isAuthenticated =
    isExpired === "false" &&
    token &&
    token !== "undefined" 
    // &&
    // account &&
    // account[0]?.id;
  const listPosition = useAppSelector((state) => state.position.allPosition);
  const coinInfo = useAppSelector((state) => state.masterdataFuture.coinInfo);
  const [listAsset, setListAsset] = useState<any[]>([]);
  const { t } = useTranslation("common");
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  useEffect(() => {
    const filterAssetCoinM = account?.filter(
      (item) => item?.asset !== USD && item?.asset !== USDT
    );
    if (hideAsset) {
      const filterAvailableAsset = filterAssetCoinM?.filter(
        (item) => Number(item.balance) !== 0
      );
      setListAsset(filterAvailableAsset);
    } else {
      setListAsset(filterAssetCoinM);
    }
  }, [hideAsset, account]);

  const handleTransformIcon = (item: string) => {
    const coinImg: any = coinInfo?.find(
      (coin) => coin.symbol === item.toLowerCase()
    );
    return coinImg ? (
      <img
        src={item}
        alt=""
        style={{ objectFit: "contain" }}
        width={30}
        height={30}
      />
    ) : (
      <BsCoin
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
        style={{ zoom: 3, objectFit: "contain" }}
      />
    );
  };

  const handleUnreailzedPNL = (asset: any) => {
    const positionOfSymbol = listPosition?.filter(
      (item: any) => asset === item?.asset
    );
    if (positionOfSymbol.length > 0) {
      let countPNL = 0;
      positionOfSymbol.forEach((item: any) => {
        countPNL = countPNL + Number(PositionCalculator.getUnrealizedPNL(item));
      });
      return countPNL.toString();
    } else {
      return "0.00";
    }
  };

  const marginBalance = (balance: string, pnl: string) => {
    return new BigNumber(balance).plus(pnl).toString();
  };

  return (
    <div className={"height-100"}>
      <div className={cx("tbl_data")}>
        {!isAuthenticated && (
          <div className={cx("no-orders")}>
            <div className={cx("warning-table")}>
              <strong onClick={() => handleDirect("login")}>
                {t("order.login")}
              </strong>{" "}
              {t("order.or")}{" "}
              <strong onClick={() => handleDirect("signup")}>
                {t("order.signup")}
              </strong>{" "}
              {t("order.to_start_trading")}
            </div>
          </div>
        )}

        {isAuthenticated && (
          <div className={"height-100"}>
            <div className={cx("table-content")} style={{ minHeight: "450px" }}>
              <table
                className={cx("dex_table", "text-table-xs", "table-asset")}
              >
                <thead>
                  <tr>
                    <th>{t("order.asset")}</th>
                    <th>{t("order.wallet_balance")}</th>
                    <th>{t("order.unrealized_pnl")}</th>
                    <th>{t("order.margin_balance")}</th>
                    <th>{t("order.available_for_order")}</th>
                    <th>{t("order.action")}</th>
                  </tr>
                </thead>
                <tbody
                  className={cx("dex_table__scrollbar", "overflow-tbody")}
                  style={{ maxHeight: 210 }}
                >
                  {loading && (
                    <tr className={cx("no-color")}>
                      <td colSpan={6}>
                        <div className={cx("loading")}>
                          <Loading />
                        </div>
                      </td>
                    </tr>
                  )}
                  {!loading && !listAsset.length && (
                    <tr className={cx("no-color")}>
                      <td colSpan={6}>
                        <div className={cx("no-data")}>
                          <BsInboxesFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            style={{ zoom: 5 }}
                          />
                          <div className={cx("no-data-desc")}>
                            {t("order.no_asset")}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {!loading && !!listAsset.length && (
                    <>
                      {listAsset.map((item, index) => (
                        <tr key={index}>
                          <td className={cx("text-value")}>
                            <div className={cx("asset")}>
                              {handleTransformIcon(item?.asset)}{" "}
                              <BsCoin
                                fill={
                                  theme === THEME_MODE.LIGHT ? "black" : "white"
                                }
                                style={{ zoom: 3, objectFit: "contain" }}
                              />
                              {item?.asset}
                            </div>
                          </td>
                          <td className={cx("text-value")}>
                            {/* <TooltipNumber
                              defaultValue={item.balance}
                              decimal={8}
                              characters={20}
                              customText={cx('value-span')}
                            /> */}
                            <TextNumber
                              defaultValue={item.balance}
                              isCoinM={false}
                              customText={cx("value-span")}
                            />
                          </td>
                          <td className={cx("text-value")}>
                            {/* <TooltipNumber
                              defaultValue={handleUnreailzedPNL(item?.asset?.toUpperCase())}
                              decimal={8}
                              characters={20}
                              customText={cx('value-span')}
                            /> */}
                            <TextNumber
                              defaultValue={handleUnreailzedPNL(
                                item?.asset?.toUpperCase()
                              )}
                              isCoinM={false}
                              customText={cx("value-span")}
                            />
                          </td>
                          <td className={cx("text-value")}>
                            {/* <TooltipNumber
                              defaultValue={marginBalance(item.balance, handleUnreailzedPNL(item?.asset.toUpperCase()))}
                              decimal={8}
                              characters={20}
                              customText={cx('value-span')}
                            /> */}
                            <TextNumber
                              defaultValue={marginBalance(
                                item.balance,
                                handleUnreailzedPNL(item?.asset.toUpperCase())
                              )}
                              isCoinM={false}
                              customText={cx("value-span")}
                            />
                          </td>
                          <td className={cx("text-value")}>
                            {/* <TooltipNumber
                              defaultValue={getAvailableBalance(item?.asset.toUpperCase())}
                              decimal={8}
                              characters={20}
                              customText={cx('value-span')}
                            /> */}
                            <TextNumber
                              defaultValue={getAvailableBalance(
                                item?.asset.toUpperCase()
                              )}
                              isCoinM={false}
                              customText={cx("value-span")}
                            />
                          </td>
                          <td className={cx("text-value")}>
                            <span
                              className={cx("transfer")}
                              onClick={() => {
                                setOpenTransfer(true);
                                setDefaultAsset(item?.asset);
                              }}
                            >
                              {t("order.transfer")}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
              {openTransfer && (
                <Transfer
                  isOpen={openTransfer}
                  onClose={() => setOpenTransfer(false)}
                  defaultCoinSelect={defaultAsset}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Assets;
