import React, { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
// import { downCancelDark, downCancelLight, iconSearchSelect, iconX } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import useOnClickOutside from 'src/hooks/useClickOutside';
import { useWindowSize } from 'src/hooks/useScreen';
import styled from '@emotion/styled';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
import { BsSearch,BsFillXOctagonFill, BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
// import CancelAllOrderModal from '../CancelAllOrderModal';

type Props = {
  isSearch?: boolean;
  dataList: any;
  placeHolder?: any;
  isCancel?: any;
  customSelect?: any;
  className?: any;
  setValueCancelFilter: Dispatch<SetStateAction<string>>;
  setIsOpenCancel: Dispatch<SetStateAction<boolean>>;
  isReset: number;
  setIsReset: Dispatch<SetStateAction<number>>;
};

const cx = classnames.bind(styles);

type SelectWrapperType = {
  theme: string;
  isMobile?: boolean;
  size: number;
};

const PlaceHolder = styled.div<SelectWrapperType>`
  display: block;
  flex-shrink: 0;
  font-size: ${(props) => (props.size <= 970 ? '14px' : '18px')};
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#888888' : '#6D7387')};
`;

export const SelectFilterCancelOrder: React.FC<Props> = ({
  dataList,
  isSearch,
  placeHolder,
  isCancel,
  customSelect,
  className,
  setValueCancelFilter,
  setIsOpenCancel,
  isReset,
  setIsReset,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const [optionSelect, setOptionSelect] = useState(dataList[0]);
  const el = useRef(null);
  const [textSearch, setTextSearch] = useState('');
  const [dataSelect, setDataSelect] = useState(dataList);
  const size = useWindowSize();
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation('common');

  useEffect(() => {
    setOptionSelect(dataList[0]);
    if (isReset !== 0) {
      setValueCancelFilter('');
    }
  }, [dataList, isReset, setValueCancelFilter]);

  const handleSearch = (text: string) => {
    setTextSearch(text);

    const resultSearch = dataList?.filter(
      (item: any) => item.name && item.name.toLowerCase().includes(text.toLowerCase()),
    );
    setDataSelect(resultSearch);
  };

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const fn = (text: string, count: number) => {
    return text?.slice(0, count) + (text?.length > count ? '...' : '');
  };

  return (
    <div className={cx("select-wrapper", className)} ref={el}>
      {!isCancel ? (
        <div
          className={cx("select-value", customSelect)}
          onClick={() => setOpenSelect(!openSelect)}
        >
          <PlaceHolder size={size[0]} theme={theme}>
            {size[0] === 280 ? fn(placeHolder, 6) : placeHolder}
          </PlaceHolder>
          <div className={cx("filter-action")}>
            <div>{t(optionSelect?.name)}</div>
            {openSelect ? (
              <BsCaretUpFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2 }}
              />
            ) : (
              <BsCaretDownFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2 }}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className={cx("select-value-no-first")}
          onClick={() => setOpenSelect(!openSelect)}
        >
          <PlaceHolder size={size[0]} theme={theme}>
            {size[0] === 280 ? fn(placeHolder, 6) : t(placeHolder)}
          </PlaceHolder>
          {openSelect ? (
            <BsCaretUpFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.2 }}
            />
          ) : (
            <BsCaretDownFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.2 }}
            />
          )}
        </div>
      )}

      {openSelect && (
        <div className={cx("select-list")}>
          {isSearch && (
            <div className={cx("select-search")}>
              <input
                placeholder={t("search_symbol")}
                value={textSearch}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <BsFillXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2, cursor: "pointer" }}
                onClick={() => setTextSearch("")}
              />
              <BsSearch
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2, cursor: "pointer" }}
              />
            </div>
          )}

          {dataSelect.length > 0 ? (
            dataSelect?.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={cx("select-item")}
                  onClick={() => {
                    setValueCancelFilter(item.value);
                    setOptionSelect(item);
                    setIsOpenCancel(true);
                    setOpenSelect(false);
                    setIsReset(0);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t(item.name)}
                </div>
              );
            })
          ) : (
            <div className={cx("no-result")}>{t("pair.no_result_found")}</div>
          )}
        </div>
      )}
    </div>
  );
};
