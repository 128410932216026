import React from "react";
import {
  IconRotate,
  IconWrapper,
} from "src/features/TradingViewChart/Components/Chart/styles";
// import {
//   FilterChartIcon,
//   ArrowDownChartIcon,
//   IconBars,
//   IconCandles,
//   IconHollowCandles,
//   IconHeikinAshi,
//   IconLine,
//   IconArea,
// } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { IChartingLibraryWidget } from "src/charting_library/charting_library.min";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import {
  BsCaretDownFill,
  BsBarChartFill,
  BsAlignCenter,
  BsAlignMiddle,
  BsFillPieChartFill,
  BsBarChartSteps,
  BsAlt,
} from "react-icons/bs";
import { AiFillFilter } from "react-icons/ai";

type IconBoxProps = {
  isShowFavoredList: boolean;
  tradingViewChart?: IChartingLibraryWidget;
};

type IconRotateProps = {
  isChooseOption?: number;
  isShowFavoredList: boolean;
};

const IconBox: React.FC<IconBoxProps & IconRotateProps> = ({
  isShowFavoredList,
  isChooseOption,
  tradingViewChart,
}) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  return (
    <IconWrapper>
      {isChooseOption === 0 ? (
        <span className="icon icon-heikin-ashi2" />
      ) : isChooseOption === 1 ? (
        <span
          className="icon icon-bar"
          onClick={() => tradingViewChart?.chart().setChartType(0)}
        />
      ) : isChooseOption === 2 ? (
        <span
          className="icon icon-heikin-ashi2"
          onClick={() => tradingViewChart?.chart().setChartType(1)}
        />
      ) : isChooseOption === 3 ? (
        <span
          className="icon icon-heikin-ashi3"
          onClick={() => tradingViewChart?.chart().setChartType(9)}
        />
      ) : isChooseOption === 4 ? (
        <span
          className="icon icon-heikin-ashi"
          onClick={() => tradingViewChart?.chart().setChartType(8)}
        />
      ) : isChooseOption === 5 ? (
        <span
          className="icon icon-line2"
          onClick={() => tradingViewChart?.chart().setChartType(2)}
        />
      ) : (
        <span
          className="icon icon-line3"
          onClick={() => tradingViewChart?.chart().setChartType(3)}
        />
      )}

      <IconRotate isShowFavoredList={isShowFavoredList}>
        <span className="icon-arrow1 icon_drop" style={{ zoom: 0.75 }} />
      </IconRotate>
    </IconWrapper>
  );
};

export default IconBox;
