import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { THEME_MODE } from 'src/interfaces/theme';

type DarkModeType = {
  theme: string;
  webview?: boolean;
};

const colors = {
  colorMirage: '#191f2b',
  colorGrey: '#999999',
};

export const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:first-child {
    width: 100%;
    height: 100%;
  }
`;

export const FullScreen = styled.div`
  background: transparent !important;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChartOption = styled.div<DarkModeType>`
  flex-shrink: 0;
  /* min-width: 393px; */
  height: 100%;
  padding: 0px 10px;
  height: auto;
  line-height: 28px;
  font-size: 12px;
  color: ${colors.colorGrey};
  display: flex;
  padding: 18px 0 22px 0px;
  background: ${(props) =>
    props.webview
      ? props.theme === THEME_MODE.DARK
        ? '#0C1114'
        : '#FFFFFF'
      : props.theme === THEME_MODE.DARK
      ? '#1b1d24'
      : '#fbfbfb'};
  @media only screen and (max-width: 400px) {
    padding: 14px 0 14px 0px;
  }
`;

export const TimeOption = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  & .time-option {
    border: none !important;
    width: 110px;
    font-size: 14px !important;
  }
`;

type PropsOption = {
  isActive?: string;
};

export const OptionDay = styled.div<PropsOption>`
  z-index: 101;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 8px; */
  position: relative;
  height: 28px;
  min-width: 35px;
  border-radius: 3px;
  color: var(--color-label-4);
  /* margin-right: 3px; */
  cursor: pointer;
  font-size: 14px;
  &:hover {
    /* background-color: #2b3038; */
    color: #00ddb3;
    transition: 0.2s;
    div {
      color: #00ddb3;
    }
  }

  ${(props) =>
    props.isActive === '1D'
      ? css`
          /* background-color: #2b3038; */
          color: #00ddb3 !important;
        `
      : css``}
`;

export const OptionWeek = styled.div<PropsOption>`
  z-index: 101;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 8px; */
  color: var(--color-label-4);
  position: relative;
  height: 28px;
  min-width: 35px;
  border-radius: 3px;
  /* margin-right: 3px; */
  cursor: pointer;
  font-size: 14px;
  &:hover {
    /* background-color: #2b3038; */
    color: #00ddb3;
    transition: 0.2s;
    div {
      color: #00ddb3;
    }
  }

  ${(props) =>
    props.isActive === '1W'
      ? css`
          /* background-color: #2b3038; */
          color: #00ddb3 !important;
        `
      : css``}
`;

export const OptionMonth = styled.div<PropsOption>`
  z-index: 101;
  border-right: 0px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 8px; */
  position: relative;
  min-width: 35px;
  color: var(--color-label-4);
  border-radius: 3px;
  /* margin-right: 3px; */
  cursor: pointer;
  font-size: 14px;
  &:hover {
    /* background-color: #2b3038; */
    color: #00ddb3;
    transition: 0.2s;
    div {
      color: #00ddb3;
    }
  }

  ${(props) =>
    props.isActive === '1M'
      ? css`
          /* background-color: #2b3038; */
          color: #00ddb3 !important;
        `
      : css``}
`;

export const Option = styled.div<PropsOption>`
  z-index: 101;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 8px; */
  min-width: 35px;
  border-radius: 3px;
  /* margin-right: 3px; */
  height: 28px;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-label-4);
  path {
    /* fill: var(--color-label-4); */
  }

  .iconLineChartUpIcon,
  .iconSettingFilledIcon,
  .iconRoundZoomOutIcon,
  .iconEnctypeArrowUpIcon,
  .iconOption,
  .iconEnctypeArrowDownIcon {
    path {
      fill: var(--color-label-4);
    }
  }
  .iconOptionArrowDown {
    path {
      stroke: var(--color-label-4);
    }
  }

  &:hover {
    /* background-color: #2b3038; */
    color: #00ddb3;
    transition: 0.2s;
    .iconLineChartUpIcon {
      path {
        fill: #00ddb3;
      }
    }

    .iconSettingFilledIcon {
      path {
        fill: #00ddb3;
      }
    }

    .iconRoundZoomOutIcon {
      path {
        fill: #00ddb3;
      }
    }

    .iconEnctypeArrowUpIcon {
      path {
        fill: #00ddb3;
      }
      rect {
        fill: #00ddb3;
      }
    }

    .iconEnctypeArrowDownIcon {
      path {
        fill: #00ddb3;
      }
      rect {
        fill: #00ddb3;
      }
    }

    .iconOption {
      path {
        fill: #00ddb3;
      }
    }

    .iconOptionArrowDown {
      path {
        stroke: #00ddb3;
      }
    }
  }
`;

export const OverLay = styled.div`
  display: block;
  z-index: 100;
  position: absolute;
  background: transparent;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  cursor: default;
`;

export const OptionCustomMinute = styled.div`
  z-index: 101;
  border-right: 0px;
  display: flex;
  z-index: 101;
  height: 28px;
  justify-content: space-around;
  align-items: center;
  /* min-width: 95px; */
  white-space: nowrap;
  border-radius: 3px;
  margin-right: 3px;
  cursor: pointer;
  font-size: 14px;
  padding: 0 10px;
  margin-left: 10px;
  color: var(--color-label-4);
  user-select: none;
  &:hover {
    /* background-color: #2b3038; */
    color: #00ddb3;
    transition: 0.2s;
    .iconArrowDownFill {
      path {
        /* stroke: #00ddb3; */
        fill: #00ddb3;
      }
    }
  }
`;

export const OptionDot = styled.div`
  border-right: 0px;
  display: flex;
  z-index: 101;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  border-radius: 3px;
  margin-right: 3px;
  /* cursor: pointer; */
  font-size: 14px;
  padding: 0 10px;
  position: relative;
`;

export const OptionCustomHours = styled.div`
  z-index: 101;
  border-right: 0px;
  height: 28px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* min-width: 70px; */
  white-space: nowrap;
  padding: 0px 10px;
  border-radius: 3px;
  margin-right: 3px;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-label-4);
  user-select: none;
  &:hover {
    /* background-color: #2b3038; */
    color: #00ddb3;
    transition: 0.2s;
    .iconArrowDownFill {
      path {
        /* stroke: #00ddb3; */
        fill: #00ddb3;
      }
    }
  }
`;

type LabelProps = {
  theme: string;
};

export const Label = styled.div<LabelProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &:hover {
    color: #00ddb3 !important;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    .icon {
      path {
        fill: var(--color-primary-1);
        stroke: var(--color-primary-1);
        /* transition: fill 0.2s ease-in-out; */
      }
    }
    .iconOption {
      path {
        fill: var(--color-primary-1);
      }
    }
    .iconCandlesSingle {
      path {
        stroke: var(--color-primary-1);
        &:nth-child(4) {
          fill: var(--color-primary-1);
        }
      }
    }
    .iconCandles {
      path {
        stroke: var(--color-primary-1);
      }
    }
    .iconLine {
      path {
        fill: var(--color-primary-1);
        stroke: var(--color-primary-1);
      }
    }
  }
`;

export const DropDownListWrapper = styled.div`
  position: relative;
  box-shadow: 0px 0px 3px 0px #bfbfbf;
`;

type IconRotateProps = {
  isChooseOption?: number;
  isShowFavoredList: boolean;
};

export const IconRotate = styled.div<IconRotateProps>`
  ${(props) =>
    props.isShowFavoredList
      ? css`
          transform: rotate(180deg);
          transition: 0.1s;
        `
      : css`
          transform: rotate(0deg);
          transition: 0.1s;
        `}
`;

export const BoxFavoredList = styled.div`
  position: absolute;
  top: 100%;
  right: -10px;
  width: 195px;
  z-index: 999;
  margin-top: 25px;
  height: auto;
`;

export const FavoredList = styled.div<DarkModeType>`
  background-color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#ffffff' : '#2d303b')};
  margin: 0;
  padding: 5px 0px;
  box-shadow: 0px 0px 3px 0px #bfbfbf;
  border-radius: 8px;
`;

type FavoredOptionProps = {
  isChooseOption?: number;
} & DarkModeType;

export const FavoredOption = styled.div<FavoredOptionProps>`
  display: flex;
  flex-direction: row;
  width: auto;
  line-height: 20px;
  cursor: pointer;
  padding: 3px 14px;
  font-size: 14px;
  overflow: hidden;
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#888')};

  &:hover {
    color: #00ddb3;
    transition: 0.1s;
  }
`;

export const FavoredOptionDropdown = styled.div<FavoredOptionProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 20px;
  cursor: pointer;
  padding: 3px 14px;
  font-size: 14px;
  overflow: hidden;
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#888')};

  .icon {
    path {
      /* fill: #ffffff; */
      /* stroke: #333333; */
      /* transition: fill 0.2s ease-in-out; */
    }
  }

  .iconLine {
    path {
      fill: #888888;
      stroke: #888888;
    }
  }

  span {
    color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#888888')};
  }

  &:hover {
    color: #00ddb3;
    transition: 0.1s;

    span {
      color: #00ddb3;
    }

    .icon {
      path {
        fill: #00ddb3;
        stroke: #00ddb3;
        /* transition: fill 0.2s ease-in-out; */
      }
    }

    .iconCandlesSingle {
      path {
        stroke: var(--color-primary-1);
        &:nth-child(4) {
          fill: var(--color-primary-1);
        }
      }
    }

    .iconCandles {
      path {
        stroke: #00ddb3;
      }
    }

    .iconLine {
      path {
        fill: #00ddb3;
        stroke: #00ddb3;
      }
    }
  }

  ${(props) =>
    props.isChooseOption !== 0
      ? css`
          color: #00ddb3;
        `
      : css``}
`;

export const IconOption = styled.div`
  width: 20px;
  flex-shrink: 0;
  margin-right: 9px;
`;

type LabelOptionProps = {
  minuteSelected?: boolean;
  hourSelected?: boolean;
} & FavoredOptionProps;

export const LabelOption = styled.span<LabelOptionProps>`
  color: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#333333' : '#888')};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #00ddb3;
    transition: 0.1s;
  }

  ${(props) =>
    props.minuteSelected || props.hourSelected
      ? css`
          color: #00ddb3;
          transition: 0.1s;
        `
      : css``}
`;

export const LabelOptionDropDown = styled.span<LabelOptionProps>`
  color: ${(props) => (props.theme === THEME_MODE.DARK ? '#00ddb3' : '#333333')};
  ${(props) =>
    props.isChooseOption !== 0
      ? css`
          color: #00ddb3;
        `
      : css``}
`;

export const ChartContent = styled.div`
  flex: 1;
  height: calc(100% - 40px);
  width: 100%;
  min-width: 295px;
  background: var(--bg-overview-4) !important;
`;

type typeChart = {
  width?: boolean;
};

export const ChartType = styled.div<typeChart>`
  width: ${(props) => (props.width ? 'auto' : '100%')};
  margin-left: ${(props) => (props.width ? '10px' : '0')};
  justify-content: flex-end;
  height: 100%;
  z-index: 101;
  display: flex;
  margin-right: 10px;
  > * {
    &:first-child {
      margin-right: 14px;
    }
  }
  > * {
    &:last-child {
      margin-left: 12px;
    }
  }
`;

export const BoxFavoredListTime = styled.div`
  position: absolute;
  top: 100%;
  right: -38px;
  width: auto;
  z-index: 999;
  margin-top: 25px;
  height: auto;
`;

export const LabelTimeFill = styled.div`
  width: 100%;
  &.active {
    color: #00ddb3 !important;
  }
  &:hover {
    color: #00ddb3 !important;
  }
`;

type PropsIconRotateFill = {
  isShowFavoredListMinute?: boolean;
  isShowFavoredListHours?: boolean;
};

export const IconRotateFill = styled.div<PropsIconRotateFill>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  ${(props) =>
    props.isShowFavoredListMinute || props.isShowFavoredListHours
      ? css`
          transform: rotate(180deg);
          transition: 0.1s;
        `
      : css`
          transform: rotate(0deg);
          transition: 0.1s;
        `}
`;

export const MoreOptionBox = styled.div<DarkModeType>`
  position: absolute;
  top: 35px;
  /* right: 278px; */
  display: flex;
  right: 0;
  padding: 10px 0;
  background: ${(props) => (props.theme === THEME_MODE.LIGHT ? '#ffffff' : '#2d303b')};
  width: 210px;
  border: 1px solid #888;
  flex-wrap: wrap;
`;

type ChartTypeDepthProps = {
  chartActive: string;
};

export const ChartTypeTradingView = styled.div<ChartTypeDepthProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  color: #888888;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  ${(props) =>
    props.chartActive === 'trading-view'
      ? css`
          color: #00ddb3;
        `
      : css``}
  &:hover {
    color: #00ddb3;
  }
`;

export const ChartTypeDepth = styled.div<ChartTypeDepthProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-label-4);
  cursor: pointer;
  ${(props) =>
    props.chartActive === 'depth'
      ? css`
          color: #00ddb3;
        `
      : css``}
  &:hover {
    color: #00ddb3;
  }
`;

export const TimeOptionWebview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  & .time-option {
    border: none !important;
    width: 110px;
    font-size: 14px !important;
  }
`;

export const OptionsWebview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-time-webview);
  border-radius: 4px;
  width: 100%;
  height: 25px;
  margin: -8px 15px;

  @media only screen and (max-width: 350px) {
    margin: -8px 4px;
  }
`;

export const DepthOption = styled.div<ChartTypeDepthProps>`
  font-size: 13px;
  color: var(--color-text-webview);
  opacity: 0.3;
  cursor: pointer;
  padding: 0 10px;
  ${(props) =>
    props.chartActive === 'depth'
      ? css`
          color: var(--color-text-webview) !important;
          opacity: 0.8;
        `
      : css``}
`;
export const Line = styled.div`
  height: 12px;
  width: 2px;
  background-color: var(--color-last-price);
  opacity: 0.1;
`;

export const TimeValue = styled.div<{ isActive?: boolean }>`
  position: relative;
  font-size: 13px;
  color: var(--color-text-webview);
  opacity: 0.5;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media only screen and (max-width: 350px) {
    padding: 14px 5px 14px 5px;
  }

  ${(props) =>
    props.isActive
      ? css`
          color: var(--color-text-webview) !important;
          opacity: 0.8;
        `
      : css``}
`;
