import BigNumber from "bignumber.js";
import classnames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
// import {
//   arrowDecrease,
//   arrowIncrease,
//   askObIconDark,
//   bidObIconDark,
//   bothObIconDark,
//   IconArrowIncrease,
//   iconObAsk,
//   iconObBid,
//   iconObBoth,
// } from 'src/assets/icon';
import IconBothSellBuy from "src/assets/icon/images/icons/bothSellBuy.svg";
import IconBothSellBuyDark from "src/assets/icon/images/icons/bothSellBuy-dark.svg";
import IconBuy from "src/assets/icon/images/icons/buy.svg";
import IconBuyDark from "src/assets/icon/images/icons/buy-dark.svg";
import IconSell from "src/assets/icon/images/icons/sell.svg";
import IconSellDark from "src/assets/icon/images/icons/sell-dark.svg";
import Icon from "src/assets/icon/icon.png";
import { CSelect } from "src/components/cores/Select";
import MarketTradeTable from "src/features/OrderbookTrade/components/MarketTradeTable";
import OrderbookTable from "src/features/OrderbookTrade/components/OrderbookTable";
import styles from "src/features/OrderbookTrade/Orderbook.module.scss";
import {
  addEmptyRows,
  calculateTotalAndPercent,
  groupRows,
  roundRows,
} from "src/features/OrderbookTrade/orderbookHelper";
import {
  getMarketTrade,
  IMarketTrade,
} from "src/features/OrderbookTrade/redux/MarketTrade.slice";
import {
  getOrderbook,
  IOrderbook,
} from "src/features/OrderbookTrade/redux/Orderbook.slice";
import {
  getIconClassName,
  getValueClassName,
} from "src/helpers/numberFormatter";
import { THEME_MODE } from "src/interfaces/theme";
import { Instrument } from "src/services/instrument";
import { useAppSelector } from "src/store/hooks";
import TooltipText from "src/components/cores/TooltipText";
import TooltipNumber from "src/components/cores/Tooltip";
import { RootState } from "src/store/store";
import { useTranslation } from "react-i18next";
import { BsArrowBarUp } from "react-icons/bs";

const cx = classnames.bind(styles);

export enum TableType {
  bids,
  asks,
  trade,
}

export enum FilterType {
  both,
  bids,
  asks,
}

const OrderbookTrade: React.FC = () => {
  const dispatch = useDispatch();
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const { t } = useTranslation("common");
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === instrument.symbol
  );
  const maxFiguresForSize = curTradingRule[0]?.maxFiguresForSize;

  const BID_ASK_HEAD = [
    `${t("orderBook.price")}(${instrument.quoteCurrency})`,
    `${t("orderBook.size")}(${isCoinM ? "Cont" : instrument.rootSymbol})`,
    `${t("orderBook.total")}(${isCoinM ? "Cont" : instrument.rootSymbol})`,
  ];
  const BID_ASK_TRADE = [
    `${t("orderBook.price")}(${instrument.quoteCurrency})`,
    `${t("orderBook.amount")}(${isCoinM ? "Cont" : instrument.rootSymbol})`,
    `${t("orderBook.time")}`,
  ];

  const currentInstrument: Instrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const orderbook: IOrderbook = useAppSelector(
    (state) => state.orderbook.orderbook
  );
  const [formattedOrderbook, setFormattedOrderbook] = useState<IOrderbook>({
    bids: [],
    asks: [],
    updatedAt: 0,
  });

  const marketTrade: IMarketTrade[] = useAppSelector(
    (state) => state.marketTrade.marketTrade
  );
  const [filterType, setFilterType] = useState<FilterType>(FilterType.both);
  const [precisionOptions, setPrecisionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [precision, setPrecision] = useState<string>(
    !!currentInstrument.tickSize ? currentInstrument.tickSize : "0.01"
  );
  const ticker = useAppSelector((state) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );

  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );

  const { height: orderbookHeight, ref: orderbookRef } = useResizeDetector();

  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const getIconPriceChange = (value: string | undefined) => {
    if (value === undefined || value === null) {
      return "";
    }
    const number = parseFloat(value);
    if (number > 0) {
      return Icon;
    } else if (number === 0) {
      return "";
    } else {
      return Icon;
    }
  };

  useEffect(() => {
    if (currentInstrument.symbol) {
      dispatch(getOrderbook(currentInstrument.symbol));
      dispatch(getMarketTrade(currentInstrument.symbol));
    }
  }, [currentInstrument.symbol]);

  useEffect(() => {
    let bids = roundRows(orderbook.bids, precision, BigNumber.ROUND_DOWN);
    let asks = roundRows(orderbook.asks, precision, BigNumber.ROUND_UP);

    bids = groupRows(bids);
    asks = groupRows(asks);
    // console.log("🚀 ~ useEffect ~ bids:", bids, asks);

    let buyRowNumber = 0;
    let sellRowNumber = 0;
    // const contentHeight = orderbookHeight || 0;

    // const headerHeight = 40;
    // const rowHeight = 20;
    if (filterType === FilterType.both) {
      // buyRowNumber = Math.floor((contentHeight - headerHeight) / rowHeight / 2);
      // sellRowNumber = Math.floor((contentHeight - headerHeight) / rowHeight / 2);
      buyRowNumber = 8;
      sellRowNumber = 8;
    } else if (filterType === FilterType.bids) {
      // buyRowNumber = Math.floor(contentHeight - headerHeight - 50) / rowHeight;
      // buyRowNumber = Math.max(buyRowNumber, bids.length);
      buyRowNumber = 30;
      sellRowNumber = 30;
    } else {
      // sellRowNumber = Math.floor(contentHeight - headerHeight - 50) / rowHeight;
      // sellRowNumber = Math.max(sellRowNumber, asks.length);
      sellRowNumber = 30;
      buyRowNumber = 30;
    }
    // switch (filterType) {
    //   case FilterType.both:
    //     bids = bids.slice(0, buyRowNumber);
    //     asks = asks.slice(0, sellRowNumber);
    //     break;
    //   case FilterType.bids:
    //     asks = [];
    //     break;
    //   case FilterType.asks:
    //     bids = [];
    //     break;
    // }
    bids = bids.slice(0, buyRowNumber);
    asks = asks.slice(0, sellRowNumber);
    // console.log("🚀 ~ useEffect ~ orderbook:", orderbook.bids.slice(0, 20));
    // console.log("🚀 ~ useEffect ~ bids:", bids, asks);

    const amountPrecision = new BigNumber(currentInstrument.lotSize)
      .times(currentInstrument.contractSize)
      .toString();

    const formattedOrderbook = calculateTotalAndPercent(
      bids,
      asks,
      Number(maxFiguresForSize || 2)
    );
    formattedOrderbook.bids = addEmptyRows(
      formattedOrderbook.bids,
      buyRowNumber
    );
    formattedOrderbook.asks = addEmptyRows(
      formattedOrderbook.asks,
      sellRowNumber
    );

    setFormattedOrderbook(formattedOrderbook);
  }, [orderbook, precision, filterType, orderbookHeight]);
    // console.log("🚀 ~ useEffect ~ formattedOrderbook:", formattedOrderbook)
  
    // useEffect(() => {
    //   console.log("🚀 ~ useEffect ~ 1234532:", formattedOrderbook);
    // }, [formattedOrderbook]);

  useEffect(() => {
    const options: { label: string; value: string }[] = [];
    let value = new BigNumber(currentInstrument.tickSize || "0.01").toString();
    for (let i = 0; i < 4; i++) {
      options.push({ value, label: value });
      value = new BigNumber(value).times(10).toString();
    }

    setPrecisionOptions(options);
    setPrecision(options[0].value);
  }, [currentInstrument]);

  return (
    <div className={cx("OrderbookTrade")}>
      <div className={cx("Orderbook")}>
        <div className={cx("Orderbook-title")}>{t("orderBook.order_book")}</div>
        <div className={cx("Orderbook-option")}>
          <div className={cx("option")}>
            <div className={cx("icon-wrapper", "check-tooltip")}>
              <img
                className={cx(
                  "icon",
                  `${filterType === FilterType.both ? "option-active" : ""}`
                )}
                src={
                  theme === THEME_MODE.LIGHT
                    ? IconBothSellBuy
                    : IconBothSellBuyDark
                }
                onClick={() => setFilterType(FilterType.both)}
              />
              <div className={cx("tooltip-filter")}>
                {t("orderBook.order_book")}
              </div>
            </div>
            <div className={cx("icon-wrapper", "check-tooltip")}>
              <img
                className={cx(
                  "icon",
                  `${filterType === FilterType.bids ? "option-active" : ""}`
                )}
                src={theme === THEME_MODE.LIGHT ? IconBuy : IconBuyDark}
                onClick={() => setFilterType(FilterType.bids)}
              />
              <div className={cx("tooltip-filter")}>
                {t("orderBook.buy_order")}
              </div>
            </div>
            <div className={cx("icon-wrapper", "check-tooltip")}>
              <img
                className={cx(
                  "icon",
                  `${filterType === FilterType.asks ? "option-active" : ""}`
                )}
                src={theme === THEME_MODE.LIGHT ? IconSell : IconSellDark}
                onClick={() => setFilterType(FilterType.asks)}
              />
              <div className={cx("tooltip-filter")}>
                {t("orderBook.sell_order")}
              </div>
            </div>
          </div>
          <CSelect
            className={cx("option-precision")}
            value={{ value: precision, label: precision }}
            onChange={(e): void => {
              setPrecision(e);
            }}
            menuPlacement="auto"
            placeholder={"0.1"}
            options={precisionOptions}
          />
        </div>
        <div className={cx("orderbook-content")} ref={orderbookRef}>
          <table className={cx("table-header")}>
            <thead>
              <tr>
                {BID_ASK_HEAD.map((item, idx) => {
                  return <th key={idx}>{item}</th>;
                })}
              </tr>
            </thead>
          </table>
          {(filterType === FilterType.both ||
            filterType === FilterType.asks) && (
            <OrderbookTable
              tbody={formattedOrderbook.asks}
              type={FilterType.asks}
              isExpanded={filterType === FilterType.asks}
            />
          )}
          <div className={cx("last_price_change")}>
            <div
              className={cx(
                "Orderbook-head",
                getValueClassName(ticker?.lastPriceChange)
              )}
            >
              <TooltipNumber
                defaultValue={ticker?.lastPrice}
                characters={9}
                decimal={decimal}
              />
            </div>
            <div className={cx("icon-change")}>
              {getIconClassName(ticker?.lastPriceChange) && (
                <BsArrowBarUp
                  style={{ zoom: 1.25 }}
                  className={cx(
                    "icon-change",
                    getIconClassName(ticker?.lastPriceChange)
                  )}
                />
              )}
            </div>
            <div className={cx("sub")}>
              <TooltipText text={t("orderBook.current_mark_price")}>
                <TooltipNumber
                  defaultValue={ticker?.oraclePrice}
                  characters={8}
                  decimal={decimal}
                />
              </TooltipText>
            </div>
          </div>
          {(filterType === FilterType.both ||
            filterType === FilterType.bids) && (
            <OrderbookTable
              tbody={formattedOrderbook.bids}
              type={FilterType.bids}
              isExpanded={filterType === FilterType.bids}
            />
          )}
        </div>
      </div>
      <div className={cx("line")}></div>
      <div className={cx("Trade")}>
        <div className={cx("Orderbook-head-trades")}>
          {t("orderBook.market_trades")}
        </div>
        <MarketTradeTable thead={BID_ASK_TRADE} tbody={marketTrade} />
      </div>
    </div>
  );
};

export default OrderbookTrade;
