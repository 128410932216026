/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classnames from 'classnames/bind';
import React, { useState, forwardRef, Fragment, useEffect, useRef } from 'react';
import Select, { MenuPlacement, components, ValueType } from 'react-select';
import stylesSCSS from 'src/components/cores/SelectCustom/SelectCustom.module.scss';
import 'src/components/cores/SelectCustom/selectCustom.scss';
import { TextField, InputAdornment } from '@material-ui/core';
// import { upIcon, downIcon, upIconDark, downIconDark, reduceIconDark, increaseIconDark } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import styles from "src/components/cores/SelectCustom/styles";
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
export interface ISelect {
  icon?: string;
  label: string;
  value?: any;
}

export const renderDefaultValueSelect = (string: string): ISelect => ({
  label: string,
  value: string,
});

export const renderOptionsSelect = (arr: string[]): ISelect[] => arr.map((item) => renderDefaultValueSelect(item));

interface Props {
  value?: ISelect | ISelect[];
  onChange: (value: any) => void;
  options: ISelect[];
  defaultValue?: ISelect | ISelect[];
  placeholder?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  onMenuScrollToBottom?: () => void;
  className?: string;
  isError?: boolean;
  message?: string;
  menuPlacement?: MenuPlacement;
  menuIsOpen?: boolean;
  onMenuOpen?: () => void;
  showSearchBar?: boolean;
  hideSearchBarSearchIcon?: boolean;
  valueSet?: number;
  isSearchable?: boolean;
  classNameWrapper?: string;
  isMargin?: boolean;
}

const cx = classnames.bind(stylesSCSS);

const Control = (allProps: any) => {
  const { children, ...props } = allProps;

  useEffect(() => {
    // Only for one selection
    if (
      allProps.selectProps.valueSet >= 0 &&
      allProps.getValue()[0].value !== allProps.selectProps.options[allProps.selectProps.valueSet].value
    )
      allProps.setValue(allProps.selectProps.options[allProps.selectProps.valueSet]);
  }, [allProps.selectProps.valueSet]);

  return (
    <components.Control {...props}>
      {allProps.selectProps.value && allProps.selectProps.value.icon && (
        <img className={cx('icon-option', 'control-field-icon-option')} src={allProps.selectProps.value.icon} />
      )}
      {children}
    </components.Control>
  );
};

const filterOption = (option: ISelect, string: string) => {
  if (!string) return true;
  return option.label.toLowerCase().includes(string.toLowerCase()) ? true : false;
};

const DropdownIndicator = (props: any) => {
  const { selectProps } = props;
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  let arrow;
  if (selectProps.menuIsOpen) {
    arrow = (
      <BsCaretUpFill
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
        style={{ zoom: 0.86 }}
      />
    );
  } else {
    arrow = (
      <BsCaretDownFill
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
        style={{ zoom: 0.86 }}
      />
    );
  }
  return (
    <components.DropdownIndicator {...props}>
      <>{arrow}</>
    </components.DropdownIndicator>
  );
};

const Menu = (allProps: any) => {
  const { selectProps, ...props } = allProps;
  const { onInputChange, inputValue, setInputValue, onMenuInputFocus, hideSearchBarSearchIcon } = selectProps;

  return (
    <Fragment>
      <components.Menu {...props} selectProps={selectProps}>
        <Fragment>
          <div className={cx('search-bar-container')}>
            <TextField
              variant="outlined"
              className={cx('search-bar', hideSearchBarSearchIcon ? 'search-bar-no-search-icon' : '')}
              placeholder={'Search'}
              value={inputValue}
              onChange={(e) =>
                onInputChange(e.currentTarget.value, {
                  action: 'input-change',
                })
              }
              onMouseDown={(e: any) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onFocus={onMenuInputFocus}
              InputProps={
                hideSearchBarSearchIcon
                  ? undefined
                  : {
                      startAdornment: (
                        <InputAdornment position="start">{/* <img src={SearchIcon} alt="" /> */}</InputAdornment>
                      ),
                    }
              }
            />

            <span className={cx('clear-search-icon-container')}>
              {/* <img src={CloseDarkButton} onClick={() => setInputValue('')} alt="" /> */}
            </span>
          </div>

          <div className={cx('options')}>{props.children}</div>
        </Fragment>
      </components.Menu>
    </Fragment>
  );
};

const SelectCustom: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    { onChange = () => {}, isError = false, message = '', isSearchable = false, showSearchBar = false, ...props },
    ref,
  ) => {
    const classes = styles();

    const containerRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const onDomClick = (e: any) => {
      if (containerRef !== null && containerRef.current !== null) {
        const menu = containerRef.current.querySelector('.select__menu');

        if (!containerRef.current.contains(e.target) || !menu || !menu.contains(e.target)) {
          setIsFocused(false);
          setInputValue('');
        }
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', onDomClick);

      return () => {
        document.removeEventListener('mousedown', onDomClick);
      };
    }, []);
    return (
      <div className={cx(props.classNameWrapper)} ref={containerRef}>
        <Select
          onChange={(v: any) => {
            setIsFocused(false);
            onChange(
              Array.isArray(v)
                ? v.map((item: ISelect) => (props.isMargin ? item : item.value))
                : props.isMargin
                ? v
                : v.value,
            );
          }}
          {...props}
          backspaceRemovesValue={false}
          filterOption={filterOption}
          classNamePrefix={cx('theme-select-custom')}
          className={`${props.className} ${classes.select}`}
          isSearchable={isSearchable}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            border: '0 !important',
            '&:foc': {
              border: '0 !important',
            },
            colors: {
              ...theme.colors,
              primary: '#1A88C9',
            },
          })}
          components={{ DropdownIndicator }}
          maxMenuHeight={250}
        />
        <div className="text-left">{isError && <span className="text-red-600 text-xs ">{message}</span>}</div>
      </div>
    );
  },
);

export default SelectCustom;
