import classNames from 'classnames/bind';
import React from 'react';
// import { decoration1 } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/images/bijkoex_brand_logo.png";
import styles from './Orders.module.scss';
import { BsGearFill } from "react-icons/bs";

const cx = classNames.bind(styles);

type Props = {
  title: string;
  label: string;
};

const OrderTitle: React.FC<Props> = ({ title, label }) => {
  return (
    <div className={cx("head-orders")}>
      <div className={cx("head-title")}>{title}</div>
      <div className={cx("head-label")}>{label}</div>
      {/* <BsGearFill fill="white" style={{zoom:5, position:"absolute", right:"30px"}} /> */}
      <img alt='' src={Icon} />
    </div>
  );
};

export default OrderTitle;
