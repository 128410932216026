import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import styles from 'src/features//Market/components/DialogAdjustLeverage/DialogAdjustLeverage.module.scss';
import classnames from 'classnames/bind';
import { noti } from 'src/hooks/useNoti';
import { getOrderMode } from "src/services/account";
// import dangerousICon from 'src/assets/icon/dangerous.svg';
// import plusIcon from 'src/assets/icon/plusIcon.svg';
// import minusIcon from 'src/assets/icon/minusIcon.svg';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import axiosInstance from 'src/helpers/config';
import { getTradingRule } from 'src/services/general';
import { formatIntBalance } from 'src/helpers/numberFormatter';
import { CInput } from 'src/components/cores/Input';
import BigNumber from 'bignumber.js';
import { MARGIN_MODE } from '../DialogChooseMarginMode/DialogChooseMarginMode';
import { IOrder } from 'src/interfaces/order';
import { THEME_MODE } from 'src/interfaces/theme';
// import { iconCloseCommon, iconCloseCommonDark, minusIconDark, plusIconDark } from 'src/assets/icon';
import { SliderRange } from 'src/components/SliderRange/SliderRangeMUI';
import { getAvailableBalance } from 'src/helpers/balanceCalculator';
import PositionCalculator, { multiplierIns } from 'src/helpers/PositionCalculator';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import {
  BsXOctagonFill,
  BsFillInfoSquareFill,
  BsArrowLeftCircle,
  BsArrowRightCircle,
} from "react-icons/bs";

const cx = classnames.bind(styles);

interface PropsCustomizeDialogsAdjustLeverage {
  isOpenAdjustLeverage: boolean;
  setIsOpenAdjustLeverage: Dispatch<SetStateAction<boolean>>;
  marginMode: string;
  leverage: number;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperWidthSm': {
    borderRadius: '8px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <BsXOctagonFill
            fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogAdjustLeverage: React.FC<PropsCustomizeDialogsAdjustLeverage> = ({
  isOpenAdjustLeverage,
  setIsOpenAdjustLeverage,
  leverage,
  marginMode,
}) => {
  const { t } = useTranslation('common');
  const instrument = useAppSelector((state) => state.instrument.currentInstrument);
  const ticker = useAppSelector((state) =>
    state.ticker.tickers?.find((ticker) => ticker.symbol === state.instrument.currentInstrument.symbol),
  );
  const leverageMarginValue = useAppSelector((state) => state.general.leverageMargin);
  const tradingRuleValue = useAppSelector((state) => state.general.tradingRule);
  const listPosition = useAppSelector((state) => state.position.positions);
  const allOrders = useAppSelector((state) => state.order.allOrders);
  const dispatch = useAppDispatch();
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const account = useAppSelector((state: RootState) => state.account.account);
  const isLogin = account && account[0]?.id;
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  const [countInput, setCountInput] = useState<number | string>(leverage);

  const leveMax = leverageMarginValue?.filter((item) => countInput <= item.maxLeverage);
  const leveOpenSort = leveMax.sort(function (a, b) {
    return a.maxLeverage - b.maxLeverage;
  });

  useEffect(() => {
    setCountInput(leverage);
  }, [leverage]);

  useEffect(() => {
    dispatch(getTradingRule(instrument.symbol));
  }, []);

  const handleCloseAdjustLeverageDialog = () => {
    setIsOpenAdjustLeverage(false);
  };

  const handleClickIncrease = () => {
    if (countInput >= Number(tradingRuleValue?.maxLeverage)) {
      setCountInput(Number(tradingRuleValue?.maxLeverage));
    } else {
      setCountInput(Number(countInput) + 1);
    }
  };

  const handleClickDecrease = () => {
    if (countInput <= 1) return;
    setCountInput(Number(countInput) - 1);
  };

  const handleChangeCount = (value: string) => {
    if (value.length !== 0) {
      if (!new RegExp('^[0-9]+$').test(value)) {
        return;
      }
    }
    if (Number(value) > Number(tradingRuleValue?.maxLeverage)) {
      setCountInput(Number(tradingRuleValue?.maxLeverage));
    } else {
      setCountInput(value);
    }
  };

  const handleConfirmAdjustLeverage = async () => {
    const modeIsCross = marginMode === MARGIN_MODE.CROSS;
    const contractPosition = listPosition && listPosition?.filter((item) => item.symbol === instrument.symbol);
    const contractOrderOpen = allOrders && allOrders?.filter((item) => item.symbol === instrument.symbol);
    const balanceAvailable = getAvailableBalance(isCoinM ? instrument?.rootSymbol : instrument?.quoteCurrency);

    // decrease leverage
    if (Number(countInput) < Number(leverage)) {
      // Cross
      if (modeIsCross) {
        let availableNew = new BigNumber(balanceAvailable);
        if (contractPosition.length > 0) {
          const amountLocked = PositionCalculator.getAllocatedMargin(contractPosition[0]);
          const leveragePosition = contractPosition[0]?.leverage;
          const amountLockedNew = new BigNumber(amountLocked).times(leveragePosition).div(countInput);
          availableNew = new BigNumber(availableNew).plus(amountLocked).minus(amountLockedNew);
        }

        if (contractOrderOpen.length > 0) {
          contractOrderOpen.map((item: IOrder) => {
            const orderMargin = new BigNumber(item?.cost || '0');
            const orderMarginNew = orderMargin.times(leverage).div(countInput);
            availableNew = new BigNumber(availableNew).plus(orderMargin).minus(orderMarginNew);
          });
        }
        if (availableNew.lt(0)) {
          noti.error({
            title: `${t('trading.leverage_smaller')}`,
          });
          return;
        }
      } // Isolated
      else {
        if (contractPosition.length > 0) {
          noti.error({
            title: `${t('trading.leverage_reduction')}`,
          });

          return;
        }

        if (contractOrderOpen.length > 0) {
          let availableNew = new BigNumber(balanceAvailable);
          contractOrderOpen.map((item: IOrder) => {
            const orderMargin = new BigNumber(item?.cost || '0');
            const orderMarginNew = orderMargin.times(leverage).div(countInput);
            availableNew = new BigNumber(availableNew).plus(orderMargin).minus(orderMarginNew);
          });
          if (availableNew.lt(0)) {
            noti.error({
              title: `${t('trading.leverage_smaller')}`,
            });
            return;
          }
        }
      }
    }

    // inscrease
    if (Number(countInput) > Number(leverage)) {
      let totalAmount = new BigNumber('0');
      if (contractPosition.length > 0) {
        const multiplier = multiplierIns(contractPosition[0]?.symbol);
        const positionAmount = isCoinM
          ? ticker?.oraclePrice
            ? new BigNumber(contractPosition[0]?.currentQty).abs().times(multiplier).div(ticker?.oraclePrice)
            : '0'
          : new BigNumber(contractPosition[0]?.currentQty).abs().times(ticker?.oraclePrice || 0);
        totalAmount = totalAmount.plus(positionAmount);
      }
      if (contractOrderOpen.length > 0) {
        contractOrderOpen.map((item: IOrder) => {
          const multiplier = multiplierIns(item?.symbol || '');
          const orderAmount = isCoinM
            ? item?.price
              ? new BigNumber(item?.quantity || '0').times(multiplier).div(item?.price)
              : 0
            : new BigNumber(item?.price || '0').times(item?.quantity || '0');
          totalAmount = totalAmount.plus(orderAmount);
        });
      }

      if (totalAmount.gt(leveOpenSort[0]?.max || 0) && (contractPosition.length > 0 || contractOrderOpen.length > 0)) {
        noti.error({
          title: `${t('trading.leverage_exceed')}`,
        });
        console.log("=====>", totalAmount.plus(1), leveOpenSort,contractPosition,contractOrderOpen);
        return;
      }
    }

    try {
      if (Number(countInput) !== Number(leverage)){
        const params = {
          instrumentId: Number(instrument.id),
          leverage: countInput.toString(),
          marginMode: marginMode,
        };
        const res:any = await axiosInstance.post("/marginMode", params)
        if(res.code === 200){
          dispatch(getOrderMode(instrument?.id));
        }
      }
        // Number(countInput) !== Number(leverage) &&
        //   (await axiosInstance.post("/marginMode", params));
      // dispatch(getOrderMode(Number(instrument.id)));
      setIsOpenAdjustLeverage(false);
    } catch (error) {}
  };

  return (
    <BootstrapDialog
      onClose={handleCloseAdjustLeverageDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpenAdjustLeverage}
    >
      <div className={cx("dialog-wrapper")}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAdjustLeverageDialog}
        >
          <div className={cx("title-dialog")}>
            {t("trading.adjust_leverage")}
          </div>
        </BootstrapDialogTitle>

        <DialogContent className={cx("dialog-content")}>
          <Typography gutterBottom>
            <div className={cx("box-count")}>
              <div className={cx("count-item")} onClick={handleClickDecrease}>
                <BsArrowLeftCircle
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 1.5 }}
                />
              </div>
              <div className={cx("count-input")}>
                <CInput
                  classNamePrefix={cx(
                    "input",
                    countInput.toString().length >= 3
                      ? "w-35"
                      : countInput.toString().length === 2
                      ? "w-20"
                      : "w-15"
                  )}
                  type="text"
                  placeholder=""
                  size="sm"
                  outline={false}
                  valueHandSet={countInput.toString()}
                  onChange={(value: string): void => {
                    handleChangeCount(value);
                  }}
                  max={Number(tradingRuleValue?.maxLeverage) || 125}
                />
                x
              </div>
              <div className={cx("count-item")} onClick={handleClickIncrease}>
                <BsArrowRightCircle
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 1.5 }}
                />
              </div>
            </div>
          </Typography>

          <div style={{ marginTop: "-20px" }}>
            <SliderRange
              value={Number(countInput)}
              handleRange={(value: number) => setCountInput(value)}
              maxRange={Number(tradingRuleValue?.maxLeverage) || 125}
              type="ajust"
            />
          </div>

          <div className={cx("description-wrapper")}>
            <div className={cx("description-item")}>
              <div className={cx("dot-wrapper")}>
                <span className={cx("dot")}></span>
              </div>
              <div className={cx("text-note")}>
                {t("trading.max_position")}:{" "}
                {formatIntBalance(leveOpenSort[0]?.max)}{" "}
                {isCoinM ? instrument?.rootSymbol : instrument?.quoteCurrency}
              </div>
            </div>
            <div className={cx("description-item")}>
              <div className={cx("dot-wrapper")}>
                <span className={cx("dot")}></span>
              </div>
              <div className={cx("text-note")}>
                {t("trading.note_leverage_changing")}
              </div>
            </div>
          </div>

          <Typography gutterBottom>
            <div className={cx("error-message")}>
              <div>
                <BsFillInfoSquareFill
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  style={{ zoom: 2 }}
                />
              </div>
              <div className={cx("message")}>
                {t("trading.selecting_higher_leverage")}
              </div>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions className={cx("dialog-action")}>
          <button
            onClick={handleConfirmAdjustLeverage}
            disabled={!Number(countInput) || !isLogin}
          >
            {t("trading.confirm")}
          </button>
        </DialogActions>
      </div>
    </BootstrapDialog>
  );
};

export default DialogAdjustLeverage;
